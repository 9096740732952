import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
import * as i4 from "./growler.service";
var ReportingService = /** @class */ (function () {
    function ReportingService(http, apiService, store, growlerService) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.growlerService = growlerService;
        this.env = environment;
    }
    ReportingService.prototype.search = function (params) {
        var dataToSend = {
            params: params
        };
        return this.http.post(this.env.apiV2Path + 'data/search', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    ReportingService.prototype.searchRRSync = function (params) {
        var dataToSend = {
            params: params
        };
        return this.http.post(this.env.apiV2Path + 'data/search/rrapi', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    ReportingService.prototype.export = function (params, segmentSelected, filename, type, voucher) {
        var dataToSend = {
            params: params,
            segmentSelected: segmentSelected,
            filename: filename,
            type: type,
            voucher: voucher
        };
        return this.http.post(this.env.apiV2Path + 'data/export', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    ReportingService.prototype.getSegments = function () {
        return this.http.get(this.env.apiV2Path + 'data/segments').pipe(map(function (data) {
            return data.data;
        }));
    };
    ReportingService.prototype.getCompanies = function () {
        return this.http.get(this.env.apiV2Path + 'companies').pipe(map(function (data) {
            return data.data;
        }));
    };
    ReportingService.ngInjectableDef = i0.defineInjectable({ factory: function ReportingService_Factory() { return new ReportingService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store), i0.inject(i4.GrowlerService)); }, token: ReportingService, providedIn: "root" });
    return ReportingService;
}());
export { ReportingService };
