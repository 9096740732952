import { HttpClient } from '@angular/common/http';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../services/api.service";
import * as i3 from "../store";
var PassportService = /** @class */ (function () {
    function PassportService(http, apiService, store) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.env = environment;
    }
    PassportService.prototype.create = function (user) {
        var dataToSend = {
            adminUser: this.store.selectForLocal('user'),
            user: user
        };
        return this.http.post(this.env.apiV2Path + 'users/admin', dataToSend)
            .pipe(map(function (data) {
            console.log(data.data);
            return data;
        }));
    };
    PassportService.prototype.update = function (passport) {
        var dataToSend = {
            adminUser: this.store.selectForLocal('user'),
            user: passport,
        };
        return this.http.put(this.env.apiV2Path + 'users/admin', dataToSend)
            .pipe(map(function (data) {
            console.log(data.data);
            return data;
        }));
    };
    PassportService.prototype.delete = function (passport) {
        var dataToSend = {
            adminUser: this.store.selectForLocal('user'),
            user: passport,
        };
        return this.http.put(this.env.apiV2Path + 'users/admin/delete', dataToSend)
            .pipe(map(function (data) {
            console.log(data.data);
            return data;
        }));
    };
    PassportService.prototype.search = function (params) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'users/search', dataToSend)
            .pipe(map(function (data) {
            console.log(data.data);
            return data;
        }));
    };
    PassportService.prototype.markAsPrinted = function (idArray) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            idArray: idArray,
        };
        return this.http.post(this.env.apiV2Path + 'users/markAsPrinted', dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    PassportService.prototype.sendWalletLink = function (userId) {
        var dataToSend = {
            userId: userId,
        };
        return this.http.post(this.env.apiV2Path + 'users/sendWalletLink', dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    PassportService.prototype.sendPurchaseLink = function (userId) {
        var dataToSend = {
            userId: userId,
        };
        return this.http.post(this.env.apiV2Path + 'users/sendPurchaseLink', dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    PassportService.prototype.get = function (id) {
        return this.http.get(this.env.apiV2Path + 'users/' + id)
            .pipe(map(function (data) {
            console.log(data.data);
            return data;
        }));
    };
    PassportService.prototype.reprint = function (id) {
        return this.http.get(this.env.apiV2Path + 'users/' + id + '/reprint')
            .pipe(map(function (data) {
            console.log(data.data);
            return data;
        }));
    };
    PassportService.prototype.confirm = function (confirmationId) {
        return this.http.get(this.env.apiV2Path + 'users/confirm/' + confirmationId)
            .pipe(map(function (data) {
            console.log(data.data);
            return data;
        }));
    };
    PassportService.prototype.getCompanies = function () {
        return this.http.get(this.env.apiV2Path + 'companies/all')
            .pipe(map(function (data) {
            return data;
        }));
    };
    PassportService.prototype.getStats = function () {
        return this.http.get(this.env.apiV2Path + 'users/stats')
            .pipe(map(function (data) {
            console.log(data.data);
            return data;
        }));
    };
    PassportService.prototype.dupeCheckEmail = function (email, userId) {
        return this.http.get(this.env.apiV2Path + 'users/email/' + email + '/' + userId)
            .pipe(map(function (data) {
            console.log(data.data);
            return data;
        }));
    };
    PassportService.ngInjectableDef = i0.defineInjectable({ factory: function PassportService_Factory() { return new PassportService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store)); }, token: PassportService, providedIn: "root" });
    return PassportService;
}());
export { PassportService };
