import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@angular/common/http";
var RateGroupService = /** @class */ (function () {
    function RateGroupService(apiService, http) {
        this.apiService = apiService;
        this.http = http;
    }
    RateGroupService.prototype.pricingGroups = function () {
        return this.http.get(this.apiService.path() + 'pricing/groups').pipe(map(function (data) {
            return data;
        }));
    };
    RateGroupService.prototype.pricingGroup = function (Id) {
        return this.http.get(this.apiService.path() + 'pricing/group/' + Id).pipe(map(function (data) {
            return data;
        }));
    };
    RateGroupService.prototype.PricingGroupCreate = function (group) {
        var dataToSend = {
            group: group
        };
        return this.http.post(this.apiService.path() + 'pricing/group/create', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    RateGroupService.prototype.PricingGroupSave = function (group) {
        var dataToSend = {
            group: group
        };
        return this.http.post(this.apiService.path() + 'pricing/group/save', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    RateGroupService.prototype.PricingGroupSaveStatus = function (group) {
        var dataToSend = {
            group: group
        };
        return this.http.post(this.apiService.path() + 'pricing/group/save/status', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    RateGroupService.ngInjectableDef = i0.defineInjectable({ factory: function RateGroupService_Factory() { return new RateGroupService(i0.inject(i1.ApiService), i0.inject(i2.HttpClient)); }, token: RateGroupService, providedIn: "root" });
    return RateGroupService;
}());
export { RateGroupService };
