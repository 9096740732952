import { BookingService } from './../../services/booking.service';
import { OnInit } from '@angular/core';
import { Store } from '../../store';
import { PassportService } from '../passport.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { UserService } from 'src/app/services/user.service';
import { GiftCardService } from 'src/app/services/gift-card.service';
var ɵ0 = MAT_MOMENT_DATE_FORMATS;
var PassportComponent = /** @class */ (function () {
    function PassportComponent(store, passportService, router, route, fb, bookingService, userService, giftCardService) {
        this.store = store;
        this.passportService = passportService;
        this.router = router;
        this.route = route;
        this.fb = fb;
        this.bookingService = bookingService;
        this.userService = userService;
        this.giftCardService = giftCardService;
        this.isNew = true;
        this.dupeEmail = false;
        this.passportId = '';
        this.dob = new Date();
        this.submitted = false;
        this.passportCompanies = [];
        this.tmpPassportPreferences = [];
        this.transactions = [];
        this.feedback = [];
        this.bookings = [];
        this.giftcards = [];
        this.config = {
            toolbar: [
                [{ 'size': ['small', false, 'large'] }],
                ['bold', 'italic', 'underline'],
                [{ 'align': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['clean']
            ]
        };
        this.titles = [
            { id: 1, name: 'Mr' },
            { id: 2, name: 'Mrs' },
            { id: 3, name: 'Miss' },
            { id: 4, name: 'Ms' },
            { id: 5, name: 'Dr' },
            { id: 6, name: 'Other' },
        ];
        this.genders = [
            { id: 'M', name: 'Male' },
            { id: 'F', name: 'Female' },
            { id: 'BN', name: 'Non-Binary' },
            { id: 'O', name: 'Other' },
        ];
        this.discountLevels = [
            { id: 53, name: '10%' },
            { id: 54, name: '20%' },
            { id: 55, name: '30%' },
            { id: 56, name: '40%' },
            { id: 57, name: '50%' },
        ];
        this.bookingParams = {
            userId: '',
            searchTerm: '',
            name: '',
            hotelID: '',
            upgraded: '',
            hasPromo: '',
            hasExtras: '',
            county: '',
            limit: 20,
            limits: [20, 50, 75, 100],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'booking.created DESC',
            sorts: [
                { ID: 'booking.created DESC', name: 'Recent First' },
                { ID: 'booking.created ASC', name: 'Recent Last' },
                { ID: 'hotel.name ASC', name: 'Hotel Name A-Z' },
                { ID: 'hotel.name DESC', name: 'Hotel Name Z-A' },
                { ID: 'county.name DESC', name: 'County A-Z' },
                { ID: 'county.name ASC', name: 'County Z-A' },
            ],
        };
        this.transactionParams = {
            eposNowId: 0,
            searchTerm: '',
            limit: 20,
            limits: [20, 50, 75, 100],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'en_transaction.createdAt DESC',
            sorts: [
                { ID: 'en_transaction.createdAt DESC', name: 'Recent First' },
                { ID: 'en_transaction.createdAt ASC', name: 'Recent Last' },
                { ID: 'en_brand.name ASC', name: 'Brand Name A-Z' },
                { ID: 'en_brand.name DESC', name: 'Brand Name Z-A' },
            ],
        };
        this.feedbackParams = {
            cardNumber: 0,
            limit: 20,
            limits: [20, 50, 75, 100],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'feedback_invite.sent DESC',
            sorts: [
                { ID: 'feedback_invite.sent DESC', name: 'Recent First' },
                { ID: 'feedback_invite.sent ASC', name: 'Recent Last' },
            ],
        };
        this.transactionsToView = [];
        this.form = this.fb.group({
            title: ['', [Validators.required]],
            firstname: ['', [Validators.required, Validators.minLength(1)]],
            surname: ['', [Validators.required, Validators.minLength(1)]],
            company: ['', []],
            referredBy: ['', []],
            dob: ['', []],
            dobDay: ['', []],
            dobMonth: ['', []],
            dobYear: ['', []],
            gender: ['', []],
            add1: ['', []],
            add2: ['', []],
            town: ['', []],
            county: ['', []],
            postcode: ['', []],
            username: ['', [Validators.required]],
            telephone: ['', []],
            mobile: ['', []],
            staffGroup: [0, []],
            discountLevel: [0, []],
            isSupplier: [false, []],
            preferences: new FormArray([]),
            notes: ['', []]
        });
    }
    Object.defineProperty(PassportComponent.prototype, "preferencesFormArray", {
        get: function () {
            return this.form.controls.preferences;
        },
        enumerable: true,
        configurable: true
    });
    PassportComponent.prototype.ngOnInit = function () {
        this.store.set('area', 'passport');
        this.passportId = this.route.snapshot.params.id;
        if (this.passportId === 'new') {
            this.isNew = true;
            this.passport = {
                title: { id: 0 },
                firstname: '',
                surname: '',
                company: '',
                referredBy: '',
                add1: '',
                add2: '',
                town: '',
                county: '',
                postcode: '',
                gender: '',
                dobDay: '',
                dobMonth: '',
                dobYear: '',
                mobile: '',
                telephone: '',
                username: '',
                staffGroup: 0,
                discountLevel: 0,
                isSupplier: false,
                notes: ''
            };
            this.getCompanies();
        }
        else {
            this.isNew = false;
            this.get();
        }
    };
    PassportComponent.prototype.getCompanies = function () {
        var _this = this;
        this.passportService.getCompanies().subscribe(function (data) {
            _this.passportCompanies = data.data;
            _this.passportCompanies.forEach(function (company) {
                var v = false;
                if (_this.tmpPassportPreferences.indexOf(company.id) >= 0) {
                    v = true;
                }
                else {
                    v = false;
                }
                _this.preferencesFormArray.push(new FormControl(v));
            });
        });
    };
    PassportComponent.prototype.get = function () {
        var _this = this;
        this.passportService.get(this.passportId).subscribe(function (data) {
            console.log(data.data);
            _this.passport = data.data;
            _this.form.patchValue({
                title: data.data.title.id,
                firstname: data.data.firstname,
                surname: data.data.surname,
                company: data.data.company,
                referredBy: data.data.referredBy,
                add1: data.data.add1,
                add2: data.data.add2,
                town: data.data.town,
                county: data.data.county,
                postcode: data.data.postcode,
                gender: data.data.gender,
                mobile: data.data.mobile,
                telephone: data.data.telephone,
                username: data.data.username,
                staffGroup: data.data.staffGroup,
                discountLevel: data.data.discountLevel,
                isSupplier: data.data.isSupplier,
                notes: data.data.notes
            });
            if (data.data.dob) {
                _this.form.patchValue({
                    dobDay: +data.data.dob.dobDay,
                    dobMonth: +data.data.dob.dobMonth,
                    dobYear: +data.data.dob.dobYear,
                    dob: new Date(data.data.dobYear, (+data.data.dobMonth - 1), data.data.dobDay),
                });
                console.log("patched date as " + data.data.dobYear + (data.data.dobMonth - 1) + " " + data.data.dobDay);
            }
            _this.tmpPassportPreferences = data.data.preferences;
            _this.getCompanies();
            _this.giftCardsByUser();
            _this.getBookings();
            _this.getTransactions();
            _this.feedbackParams.cardNumber = data.data.cardNumber;
            _this.getFeedback();
        });
    };
    PassportComponent.prototype.bstFix = function (d) {
        var fixedDate = new Date(d);
        if (fixedDate.getUTCHours() > 22) {
            fixedDate.setTime(fixedDate.getTime() + 6 * 60 * 60 * 1000);
        }
        return fixedDate;
    };
    PassportComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        var selectedPassportCompanies = this.form.value.preferences
            .map(function (checked, i) { return checked ? _this.passportCompanies[i].id : null; })
            .filter(function (v) { return v !== null; });
        this.passport = {
            id: this.passportId,
            title: { id: this.form.value.title },
            username: this.form.value.username,
            firstname: this.form.value.firstname,
            surname: this.form.value.surname,
            company: this.form.value.company,
            referredBy: this.form.value.referredBy,
            add1: this.form.value.add1,
            add2: this.form.value.add2,
            town: this.form.value.town,
            county: this.form.value.county,
            postcode: this.form.value.postcode,
            dob: this.bstFix(new Date(this.form.value.dob)),
            gender: this.form.value.gender,
            mobile: this.form.value.mobile,
            telephone: this.form.value.telephone,
            isSupplier: this.form.value.isSupplier,
            staffGroup: this.form.value.staffGroup,
            discountLevel: this.form.value.discountLevel,
            preferences: selectedPassportCompanies,
            notes: this.form.value.notes,
        };
        var authObs;
        if (this.isNew) {
            this.passport.id = '';
            authObs = this.passportService.create(this.passport);
        }
        else {
            authObs = this.passportService.update(this.passport);
        }
        authObs.subscribe(function (resData) {
            _this.router.navigate(['/passports']);
        }, function (errorMessage) {
            console.log(errorMessage);
            // this.form.controls.password.setErrors({ 'incorrect': true });
        });
    };
    PassportComponent.prototype.numberPad = function (v) {
        if (v > 10) {
            return v;
        }
        var paddedNumber = '0' + v;
        console.log(paddedNumber);
        return paddedNumber;
    };
    PassportComponent.prototype.reprint = function () {
        this.passportService.reprint(this.passportId).subscribe(function (data) {
        });
    };
    PassportComponent.prototype.dupeCheckEmail = function () {
        var _this = this;
        this.passportService.dupeCheckEmail(this.form.value.username, this.passportId).subscribe(function (data) {
            console.log(data);
            _this.dupeEmail = data.data;
            if (data.data) {
                _this.form.controls.username.setErrors({ 'exists': true });
            }
        });
    };
    PassportComponent.prototype.dupeCheckCardNumber = function () {
    };
    PassportComponent.prototype.giftCardsByUser = function () {
        var _this = this;
        this.giftCardService.giftCardsByUser(this.passportId).subscribe(function (data) {
            console.log(data);
            _this.giftcards = data.data;
        });
    };
    PassportComponent.prototype.getBookings = function () {
        var _this = this;
        this.bookingParams.userId = this.passportId;
        this.bookingService.byCustomer(this.passportId).subscribe(function (data) {
            _this.bookings = data;
        });
    };
    PassportComponent.prototype.getTransactions = function () {
        var _this = this;
        this.transactionParams.eposNowId = this.passport.eposNowId;
        this.userService.loadTransactions(this.transactionParams).subscribe(function (data) {
            console.log(data.data);
            _this.transactions = data.data.data;
            _this.transactionParams.pages = Math.ceil(data.data.totalRecords / _this.transactionParams.limit);
            _this.transactionParams.totalRecords = data.data.totalRecords;
            _this.transactionParams.pageArray = [];
            for (var i = 0; i < _this.transactionParams.pages; i++) {
                _this.transactionParams.pageArray.push(i);
            }
        });
    };
    PassportComponent.prototype.getTransactionItems = function (id) {
        var _this = this;
        this.userService.loadTransactionItems(id).subscribe(function (data) {
            console.log(data.data);
            _this.transactionsToView = data.data;
            console.log(_this.transactionsToView);
        });
    };
    PassportComponent.prototype.toggleViewingTransactions = function (t) {
        if (!t.viewingTransactions) {
            for (var i = 0; i < this.transactions.length; i++) {
                this.transactions[i].viewingTransactions = false;
            }
            this.getTransactionItems(t.transactionId);
            t.viewingTransactions = true;
        }
        else {
            t.viewingTransactions = false;
            this.transactionsToView = [];
        }
    };
    PassportComponent.prototype.getFeedback = function () {
        var _this = this;
        this.userService.searchFeedback(this.feedbackParams).subscribe(function (data) {
            console.log(data.data);
            _this.feedback = data.data.data;
            _this.feedbackParams.pages = Math.ceil(data.data.totalRecords / _this.feedbackParams.limit);
            _this.feedbackParams.totalRecords = data.data.totalRecords;
            _this.feedbackParams.pageArray = [];
            for (var i = 0; i < _this.feedbackParams.pages; i++) {
                _this.feedbackParams.pageArray.push(i);
            }
        });
    };
    PassportComponent.prototype.toggleViewingFeedback = function (t) {
        if (!t.viewingFeedback) {
            for (var i = 0; i < this.feedback.length; i++) {
                this.feedback[i].viewingFeedback = false;
            }
            t.viewingFeedback = true;
        }
        else {
            t.viewingFeedback = false;
        }
    };
    PassportComponent.prototype.sendWalletLink = function (passportId) {
        var _this = this;
        this.passportService.sendWalletLink(passportId).subscribe(function (data) {
            console.log(data);
            _this.passport.linkSent = true;
        });
    };
    PassportComponent.prototype.sendPurchaseLink = function (confirmationId) {
        var _this = this;
        this.passportService.sendPurchaseLink(confirmationId).subscribe(function (data) {
            console.log(data);
            _this.passport.purchaseLinkSent = true;
        });
    };
    PassportComponent.prototype.viewBooking = function (booking) {
        this.router.navigate(['/', 'bookings', booking.id]);
    };
    PassportComponent.prototype.addFeedbackComment = function (invite) {
        this.feedbackCommentData = {
            invite: invite
        };
        console.log(this.feedbackCommentData);
    };
    PassportComponent.prototype.feedbackCommentDone = function (e) {
        if (e.refresh) {
            var user = this.store.selectForLocal('user');
            for (var f = 0; f < this.feedback.length; f++) {
                console.log(this.feedback[f]);
                if (this.feedback[f].id === this.feedbackCommentData.invite.id) {
                    this.feedback[f].staffResponse = e.staffResponse;
                    this.feedback[f].respondedAt = new Date();
                    this.feedback[f].username = user.firstname + " " + user.surname;
                }
            }
        }
        this.feedbackCommentData = undefined;
    };
    return PassportComponent;
}());
export { PassportComponent };
export { ɵ0 };
