/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-groups.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i9 from "@angular/material/card";
import * as i10 from "@angular/router";
import * as i11 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "./product-groups.component";
import * as i14 from "../../services/product.service";
import * as i15 from "../../services/overlay.service";
import * as i16 from "../../services/hotel.service";
import * as i17 from "../../store";
var styles_ProductGroupsComponent = [i0.styles];
var RenderType_ProductGroupsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductGroupsComponent, data: {} });
export { RenderType_ProductGroupsComponent as RenderType_ProductGroupsComponent };
function View_ProductGroupsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "mr-2 mb-2"], ["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleHotel(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.isHotelSelected(_v.context.$implicit.id) > (0 - 1)) ? "primary" : "grey"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_3); }); }
function View_ProductGroupsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mb-4 mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupsComponent_2)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hotels; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProductGroupsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-card", [["class", "no-records mat-card"]], null, null, null, i8.View_MatCard_0, i8.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i9.MatCard, [], null, null), (_l()(), i1.ɵted(-1, 0, [" No Room Extras\n"]))], null, null); }
function View_ProductGroupsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "img-fluid"], ["src", "/assets/images/nia.png"], ["style", "max-height: 30px"]], null, null, null, null, null))], null, null); }
function View_ProductGroupsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "img-fluid"], ["style", "max-height: 30px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.logo.URL; _ck(_v, 0, 0, currVal_0); }); }
function View_ProductGroupsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [", "]))], null, null); }
function View_ProductGroupsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupsComponent_9)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, ["", " "]))], function (_ck, _v) { var currVal_0 = _v.context.index; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
function View_ProductGroupsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "td", [["class", "hover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.groupUpdate(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupsComponent_6)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupsComponent_7)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [["class", "hover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.groupUpdate(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [["class", "hover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.groupUpdate(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupsComponent_8)), i1.ɵdid(10, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 10, "td", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "a", [["class", "hover"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 4), (_l()(), i1.ɵeld(15, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(16, 9158656, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null], [2, i12.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["edit"])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "a", [["class", "hover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.groupDelete(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(20, 9158656, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null], [2, i12.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete"]))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.logo.URL; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.logo.URL; _ck(_v, 5, 0, currVal_1); var currVal_3 = _v.context.$implicit.hotels; _ck(_v, 10, 0, currVal_3); var currVal_6 = _ck(_v, 14, 0, "/", "product-groups", _v.context.$implicit.ID, "edit"); _ck(_v, 13, 0, currVal_6); _ck(_v, 16, 0); _ck(_v, 20, 0); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); var currVal_4 = i1.ɵnov(_v, 13).target; var currVal_5 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 16).inline; _ck(_v, 15, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 20).inline; _ck(_v, 19, 0, currVal_8); }); }
function View_ProductGroupsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "margin-top-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "table", [["class", "mat-elevation-z2 example-full-width data-listing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Image"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Group Name"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Brands"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProductGroupsComponent_5)), i1.ɵdid(14, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.productGroups$)); _ck(_v, 14, 0, currVal_0); }, null); }
export function View_ProductGroupsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "mat-card", [["class", "breadcrumb mat-card"]], null, null, null, i8.View_MatCard_0, i8.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i9.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(-1, null, ["Room add-ons"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "col-md-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["class", "hover"], ["color", "primary"], ["id", "pullright"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(10, 16384, null, 0, i10.RouterLink, [i10.Router, i10.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 4), i1.ɵdid(12, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Add Group"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupsComponent_1)), i1.ɵdid(15, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProductGroupsComponent_3)), i1.ɵdid(17, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProductGroupsComponent_4)), i1.ɵdid(20, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 6, 0, "/products-groups"); _ck(_v, 5, 0, currVal_2); var currVal_5 = _ck(_v, 11, 0, "/", "product-groups", "", "new"); _ck(_v, 10, 0, currVal_5); var currVal_6 = "primary"; _ck(_v, 12, 0, currVal_6); var currVal_7 = _co.hotels.length; _ck(_v, 15, 0, currVal_7); var currVal_8 = !i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.productGroups$)).length; _ck(_v, 17, 0, currVal_8); var currVal_9 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform(_co.productGroups$)).length; _ck(_v, 20, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 12).disabled || null); var currVal_4 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_3, currVal_4); }); }
export function View_ProductGroupsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-groups", [], null, null, null, View_ProductGroupsComponent_0, RenderType_ProductGroupsComponent)), i1.ɵdid(1, 114688, null, 0, i13.ProductGroupsComponent, [i14.ProductService, i15.OverlayService, i16.HotelService, i17.Store, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductGroupsComponentNgFactory = i1.ɵccf("app-product-groups", i13.ProductGroupsComponent, View_ProductGroupsComponent_Host_0, {}, {}, []);
export { ProductGroupsComponentNgFactory as ProductGroupsComponentNgFactory };
