import { Store } from '../store';
import * as Cookies from 'cookies-js';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../store";
import * as i2 from "@angular/common/http";
import * as i3 from "./api.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(store, http, apiService) {
        this.store = store;
        this.http = http;
        this.apiService = apiService;
        this.cookieExpiration = 60 * 5000;
    }
    AuthenticationService.prototype.login = function (user) {
        var _this = this;
        return this.http.post(this.apiService.path() + 'authenticate', user).pipe(map(function (data) {
            if (data.data.authenticated) {
                _this.store.set('user', data.data);
                _this.store.set('showMenu', true);
                _this.setCookie(data.data);
            }
            return data;
        }));
    };
    AuthenticationService.prototype.logout = function () {
        this.store.set('user', undefined);
        this.store.set('showMenu', false);
        this.removeCookie();
    };
    AuthenticationService.prototype.setCookie = function (userData) {
        Cookies.set(AuthenticationService.LOGIN_COOKIE, JSON.stringify(userData), { expires: this.cookieExpiration });
    };
    AuthenticationService.prototype.removeCookie = function () {
        Cookies.expire(AuthenticationService.LOGIN_COOKIE);
        this.store.set('user', undefined);
    };
    AuthenticationService.prototype.cookieCheck = function () {
        var lg = Cookies.get(AuthenticationService.LOGIN_COOKIE);
        if (Cookies.get(AuthenticationService.LOGIN_COOKIE)) {
            var user = JSON.parse(Cookies.get(AuthenticationService.LOGIN_COOKIE));
            this.store.set('user', user);
            return true;
        }
        else {
            this.store.set('user', undefined);
            return false;
        }
    };
    AuthenticationService.prototype.isAuthenticated = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            resolve(_this.cookieCheck());
        });
        return promise;
    };
    AuthenticationService.LOGIN_COOKIE = 'login-cookie';
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.Store), i0.inject(i2.HttpClient), i0.inject(i3.ApiService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
