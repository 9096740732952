import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "../store";
import * as i3 from "@angular/common/http";
var EventsService = /** @class */ (function () {
    function EventsService(apiService, store, http) {
        this.apiService = apiService;
        this.store = store;
        this.http = http;
        this.env = environment;
    }
    EventsService.prototype.exportAttendees = function (eventId) {
        var user = this.store.selectForLocal('user');
        var dataToSend = {
            eventId: eventId,
            email: user.email
        };
        return this.http.post('https://bpt-api.787512.co.uk/events/export/attendees/' + eventId, dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.exportPurchasers = function (eventId) {
        var user = this.store.selectForLocal('user');
        var dataToSend = {
            eventId: eventId,
            email: user.email
        };
        return this.http.post('https://bpt-api.787512.co.uk/events/export/purchasers/' + eventId, dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.get = function (id) {
        return this.http.get(this.env.apiV2Path + 'events/' + id)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.getTypes = function () {
        return this.http.get(this.env.apiV2Path + 'events/types')
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.getType = function (slug) {
        return this.http.get(this.env.apiV2Path + 'events/types/' + slug)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.createType = function (eventType) {
        var dataToSend = {
            eventType: eventType
        };
        return this.http.post(this.env.apiV2Path + 'events/types/', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.updateType = function (eventType) {
        var dataToSend = {
            eventType: eventType
        };
        return this.http.put(this.env.apiV2Path + 'events/types/', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.deleteType = function (eventType) {
        return this.http.delete(this.env.apiV2Path + 'events/types/' + eventType.slug)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.search = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'events/search', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.searchAttendees = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'events/search/attendees', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.create = function (event) {
        var dataToSend = {
            event: event
        };
        return this.http.post(this.env.apiV2Path + 'events', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.update = function (event) {
        var dataToSend = {
            event: event
        };
        return this.http.put(this.env.apiV2Path + 'events', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.delete = function (event) {
        return this.http.delete(this.env.apiV2Path + 'events/' + event.id)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.searchEventUsers = function (params) {
        var dataToSend = {
            params: params
        };
        return this.http.post(this.env.apiV2Path + 'events/users/', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.deleteUser = function (userId, eventId) {
        return this.http.delete(this.env.apiV2Path + 'events/user/' + userId + '/' + eventId)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.searchBookings = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'events/search/bookings', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.getBooking = function (id) {
        return this.http.get(this.env.apiV2Path + 'events/bookings/' + id)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.sortSkus = function (skus) {
        var dataToSend = {
            skus: skus
        };
        return this.http.post(this.env.apiV2Path + 'events/skus/sort', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.prototype.deleteTicket = function (ticketId) {
        return this.http.delete(this.env.apiV2Path + 'events/ticket/' + ticketId)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    EventsService.ngInjectableDef = i0.defineInjectable({ factory: function EventsService_Factory() { return new EventsService(i0.inject(i1.ApiService), i0.inject(i2.Store), i0.inject(i3.HttpClient)); }, token: EventsService, providedIn: "root" });
    return EventsService;
}());
export { EventsService };
