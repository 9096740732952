import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "../store";
import * as i3 from "@angular/common/http";
var FeedbackService = /** @class */ (function () {
    function FeedbackService(apiService, store, http) {
        this.apiService = apiService;
        this.store = store;
        this.http = http;
        this.env = environment;
    }
    FeedbackService.prototype.saveResponse = function (inviteId, staffResponse) {
        var user = this.store.selectForLocal('user');
        var dataToSend = {
            inviteId: inviteId,
            staffResponse: staffResponse,
            userId: user.ID,
        };
        return this.http.post(this.env.apiV2Path + 'feedback/comment/respond', dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.searchInvites = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'feedback/invites', dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.getInvite = function (invite) {
        return this.http.get(this.env.apiV2Path + 'feedback/invite/' + invite.uuid)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.deleteInviteAnswers = function (inviteId) {
        return this.http.delete(this.env.apiV2Path + 'feedback/invite/answers/' + inviteId)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.getBusinesses = function () {
        return this.http.get(this.env.apiV2Path + 'feedback/businesses')
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.getBusiness = function (id) {
        return this.http.get(this.env.apiV2Path + 'feedback/businesses/' + id)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.getBusinessStaff = function (organisation) {
        return this.http.get(this.env.apiV2Path + 'staff/organisation/' + organisation)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.excludeStaffFromDistro = function (organisation, staff) {
        return this.http.put(this.env.apiV2Path + 'feedback/business/exclude/' + organisation + '/' + staff, {})
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.includeStaffInDistro = function (organisation, staff) {
        return this.http.put(this.env.apiV2Path + 'feedback/business/include/' + organisation + '/' + staff, {})
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.excludeAllStaffFromDistro = function (organisation) {
        return this.http.put(this.env.apiV2Path + 'feedback/business/exclude/' + organisation + '/all', {})
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.includeAllStaffInDistro = function (organisation) {
        return this.http.put(this.env.apiV2Path + 'feedback/business/include/' + organisation + '/all', {})
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.getStaffExclusions = function (organisation) {
        return this.http.get(this.env.apiV2Path + 'feedback/business/exclusions/' + organisation)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.getNonBusinessStaff = function (organisation) {
        return this.http.get(this.env.apiV2Path + 'feedback/business/inclusions/' + organisation)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.addNonStaff = function (organisation, staff) {
        var dataToSend = {
            name: staff.name,
            email: staff.email,
            organisation: organisation
        };
        return this.http.post(this.env.apiV2Path + 'feedback/business/include/', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.removeNonStaff = function (organisation, staff) {
        var dataToSend = {
            name: staff.name,
            email: staff.email,
            organisation: organisation
        };
        return this.http.post(this.env.apiV2Path + 'feedback/business/exclude/', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.getCommentsStaff = function (organisation) {
        return this.http.get(this.env.apiV2Path + 'feedback/comments/inclusions/' + organisation)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.addCommentsStaff = function (organisation, staff) {
        var dataToSend = {
            name: staff.name,
            email: staff.email,
            organisation: organisation
        };
        return this.http.post(this.env.apiV2Path + 'feedback/comments/include/', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.removeCommentsStaff = function (organisation, staff) {
        var dataToSend = {
            name: staff.name,
            email: staff.email,
            organisation: organisation
        };
        return this.http.post(this.env.apiV2Path + 'feedback/comments/exclude/', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.getTemplates = function () {
        return this.http.get(this.env.apiV2Path + 'feedback/template')
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.getTemplate = function (id) {
        return this.http.get(this.env.apiV2Path + 'feedback/template/' + id)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.createTemplate = function (template) {
        var dataToSend = {
            template: template
        };
        return this.http.post(this.env.apiV2Path + 'feedback/template', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.updateTemplate = function (template) {
        var dataToSend = {
            template: template
        };
        return this.http.put(this.env.apiV2Path + 'feedback/template', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.sortTemplates = function (templates) {
        var dataToSend = {
            templates: templates
        };
        return this.http.post(this.env.apiV2Path + 'template/sort', dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    // questions
    FeedbackService.prototype.getQuestions = function (templateId) {
        return this.http.get(this.env.apiV2Path + 'feedback/template/' + templateId + '/questions')
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.getQuestion = function (id) {
        return this.http.get(this.env.apiV2Path + 'feedback/question/' + id)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.createQuestion = function (question) {
        var dataToSend = {
            question: question
        };
        return this.http.post(this.env.apiV2Path + 'feedback/question', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.updateQuestion = function (question) {
        var dataToSend = {
            question: question
        };
        return this.http.put(this.env.apiV2Path + 'feedback/question', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.findAllTopics = function () {
        var _this = this;
        var faq = this.store.selectForLocal('faq');
        var dataToSend = {
            params: faq.params,
        };
        return this.http.post(this.env.apiV2Path + 'faq/search', dataToSend)
            .pipe(map(function (data) {
            faq.data = data.data.data;
            faq.params.pages = Math.ceil(data.data.totalRecords / faq.params.limit);
            faq.params.totalRecords = data.data.totalRecords;
            faq.params.pageArray = [];
            for (var i = 0; i < faq.params.pages; i++) {
                faq.params.pageArray.push(i);
            }
            _this.store.set('faq', faq);
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.findOne = function (id) {
        return this.http.get(this.env.apiV2Path + 'faq/' + id)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.create = function (topic) {
        var user = this.store.selectForLocal('user');
        var dataToSend = {
            topic: topic,
            user: user,
        };
        return this.http.post(this.env.apiV2Path + 'faq', dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.update = function (topic) {
        var user = this.store.selectForLocal('user');
        var dataToSend = {
            topic: topic,
            user: user,
        };
        return this.http.put(this.env.apiV2Path + 'faq', dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FeedbackService.prototype.sortQuestions = function (questions) {
        var dataToSend = {
            questions: questions
        };
        return this.http.post(this.env.apiV2Path + 'faq/questions/sort', dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    FeedbackService.ngInjectableDef = i0.defineInjectable({ factory: function FeedbackService_Factory() { return new FeedbackService(i0.inject(i1.ApiService), i0.inject(i2.Store), i0.inject(i3.HttpClient)); }, token: FeedbackService, providedIn: "root" });
    return FeedbackService;
}());
export { FeedbackService };
