/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback-stats-quarter.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./feedback-stats-quarter.component";
var styles_FeedbackStatsQuarterComponent = [i0.styles];
var RenderType_FeedbackStatsQuarterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackStatsQuarterComponent, data: {} });
export { RenderType_FeedbackStatsQuarterComponent as RenderType_FeedbackStatsQuarterComponent };
export function View_FeedbackStatsQuarterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" feedback-stats-quarter works!\n"]))], null, null); }
export function View_FeedbackStatsQuarterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback-stats-quarter", [], null, null, null, View_FeedbackStatsQuarterComponent_0, RenderType_FeedbackStatsQuarterComponent)), i1.ɵdid(1, 114688, null, 0, i2.FeedbackStatsQuarterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackStatsQuarterComponentNgFactory = i1.ɵccf("app-feedback-stats-quarter", i2.FeedbackStatsQuarterComponent, View_FeedbackStatsQuarterComponent_Host_0, {}, {}, []);
export { FeedbackStatsQuarterComponentNgFactory as FeedbackStatsQuarterComponentNgFactory };
