import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { map } from 'rxjs/operators';
import { GrowlerService } from './growler.service';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
import * as i4 from "./growler.service";
var HotelService = /** @class */ (function () {
    function HotelService(http, apiService, store, growlerService) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.growlerService = growlerService;
        this.env = environment;
        this.defaultParams = {
            searchTerm: '',
            name: '',
            county: '',
            limit: 10,
            limits: [10, 20, 30, 40],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'hotel.name ASC',
            sorts: [
                { ID: 'hotel.name ASC', name: 'Hotel Name A-Z' },
                { ID: 'hotel.name DESC', name: 'Hotel Name Z-A' },
                { ID: 'county.name DESC', name: 'County A-Z' },
                { ID: 'county.name ASC', name: 'County Z-A' },
            ],
        };
    }
    HotelService.prototype.allLite = function () {
        return this.http.get(this.env.apiV2Path + 'hotel/allLite').pipe(map(function (data) {
            return data.data;
        }));
    };
    HotelService.prototype.updateParams = function (params) {
        this.store.set('hotelParams', params);
        this.search();
    };
    HotelService.prototype.resetParams = function () {
        this.store.set('hotelParams', JSON.parse(JSON.stringify(this.defaultParams)));
        console.log(JSON.parse(JSON.stringify(this.defaultParams)));
        this.search();
    };
    HotelService.prototype.search = function () {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('hotelParams')
        };
        return this.http.post(this.apiService.path() + 'hotel/search', dataToSend).subscribe(function (data) {
            _this.store.set('hotels', data.data);
            var p = _this.store.selectForLocal('hotelParams');
            p.pages = Math.ceil(data.params.totalRecords / p.limit);
            p.totalRecords = data.params.totalRecords;
            p.pageArray = [];
            for (var i = 0; i < p.pages; i++) {
                p.pageArray.push(i);
            }
            _this.store.set('hotelParams', p);
            return data.data;
        });
    };
    HotelService.prototype.all = function () {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('hotelParams')
        };
        return this.http.post(this.apiService.path() + 'hotel/search', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    HotelService.prototype.toggleObject = function (hotel, field) {
        var data = this.store.selectForLocal('hotels');
        for (var i = 0; i < data.length; i++) {
            if (data[i].id === hotel.id) {
                if (!data[i][field]) {
                    data[i][field] = true;
                }
                else {
                    data[i][field] = false;
                }
            }
        }
        this.store.set('hotels', data);
        this.saveRemote(hotel);
    };
    HotelService.prototype.setHotel = function (hotel) {
        var data = JSON.parse(JSON.stringify(this.store.selectForLocal('hotels')));
        for (var i = 0; i < data.length; i++) {
            if (data[i].id === hotel.id) {
                data[i] = hotel;
            }
        }
        this.store.set('hotel', hotel);
        this.store.set('hotels', data);
    };
    HotelService.prototype.save = function (hotel) {
        var data = this.store.selectForLocal('hotels');
        var foundRecord = false;
        for (var i = 0; i < data.length; i++) {
            if (data[i].id === hotel.id) {
                data[i] = hotel;
                foundRecord = true;
            }
        }
        this.store.set('hotels', data);
        this.saveRemote(hotel);
        this.search();
    };
    HotelService.prototype.createRemote = function (hotel) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            hotel: hotel
        };
        return this.http.post(this.apiService.path() + 'hotel', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    HotelService.prototype.saveRemote = function (hotel) {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            hotel: hotel
        };
        return this.http.post(this.apiService.path() + 'hotel', dataToSend).subscribe(function (data) {
            if (data.errors.length) {
                _this.growlerService.error(data.errors);
            }
            else {
                _this.growlerService.notification(['Hotel details updated']);
                _this.setHotel(data.data);
            }
        });
    };
    HotelService.prototype.saveFeatureGroup = function (f) {
        var dataToSend = {
            featureGroup: f
        };
        return this.http.post(this.apiService.path() + 'hotel/features/group/save', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    HotelService.prototype.hotelFeatures = function (hotelID) {
        var dataToSend = {
            hotelID: hotelID
        };
        return this.http.post(this.apiService.path() + 'hotel/features', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    HotelService.prototype.sortHotelFeatures = function (hotelFeatures) {
        var dataToSend = {
            hotelFeatures: hotelFeatures
        };
        return this.http.post(this.apiService.path() + 'hotel/features/sort', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    HotelService.prototype.toggleFeature = function (f, hotelID) {
        var dataToSend = {
            hotelID: hotelID,
            feature: f
        };
        return this.http.post(this.apiService.path() + 'hotel/features/index', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    HotelService.prototype.addFeature = function (f) {
        var dataToSend = {
            feature: f
        };
        return this.http.post(this.apiService.path() + 'hotel/features/add', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    HotelService.prototype.deleteFeature = function (featureToDelete) {
        console.log(featureToDelete);
        var dataToSend = {
            id: featureToDelete.id
        };
        return this.http.post(this.apiService.path() + 'hotel/features/delete', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    HotelService.ngInjectableDef = i0.defineInjectable({ factory: function HotelService_Factory() { return new HotelService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store), i0.inject(i4.GrowlerService)); }, token: HotelService, providedIn: "root" });
    return HotelService;
}());
export { HotelService };
