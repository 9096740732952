import { Store } from '../store';
import * as i0 from "@angular/core";
import * as i1 from "../store";
var GrowlerService = /** @class */ (function () {
    function GrowlerService(store) {
        this.store = store;
    }
    GrowlerService.prototype.error = function (errors) {
        var g = this.store.selectForLocal('growler');
        g.errors = errors;
        g.notifications = [];
        this.store.set('growler', g);
    };
    GrowlerService.prototype.notification = function (notifications) {
        var _this = this;
        var g = this.store.selectForLocal('growler');
        g.errors = [];
        g.notifications = notifications;
        this.store.set('growler', g);
        setTimeout(function () {
            _this.clearMessage();
        }, 4000);
    };
    GrowlerService.prototype.clearMessage = function () {
        var g = this.store.selectForLocal('growler');
        g.errors = [];
        g.notifications = [];
        this.store.set('growler', g);
    };
    GrowlerService.ngInjectableDef = i0.defineInjectable({ factory: function GrowlerService_Factory() { return new GrowlerService(i0.inject(i1.Store)); }, token: GrowlerService, providedIn: "root" });
    return GrowlerService;
}());
export { GrowlerService };
