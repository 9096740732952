import { EventTypeComponent } from './events/event-type/event-type.component';
import { VoucherEditComponent } from './voucher-edit/voucher-edit.component';
import { EventComponent } from './events/event/event.component';
import { EventsComponent } from './events/events/events.component';
import { VoucherUsersComponent } from './voucher-users/voucher-users.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { ReportingComponent } from './reporting/reporting.component';
import { GiftcardSearchComponent } from './giftcard-search/giftcard-search.component';
import { FeedbackBusinessDistroComponent } from './feedback/feedback-business-distro/feedback-business-distro.component';
import { FeedbackBusinessComponent } from './feedback/feedback-business/feedback-business.component';
import { FeedbackStatsQuarterComponent } from './feedback-stats-quarter/feedback-stats-quarter.component';
import { FeedbackStatsWeekComponent } from './feedback-stats-week/feedback-stats-week.component';
import { FeedbackStatsDailyComponent } from './feedback-stats-daily/feedback-stats-daily.component';
import { FeedbackStatsClusterComponent } from './feedback-stats-cluster/feedback-stats-cluster.component';
import { FeedbackTemplatesComponent } from './feedback/feedback-templates/feedback-templates.component';
import { FeedbackQuestionsComponent } from './feedback/feedback-questions/feedback-questions.component';
import { FeedbackAnswersComponent } from './feedback/feedback-answers/feedback-answers.component';
import { FeedbackInvitesComponent } from './feedback/feedback-invites/feedback-invites.component';
import { SiteDownComponent } from './site-down/site-down.component';
import { CustomerViewComponent } from './customer/customer-view/customer-view.component';
import { FaqQuestionsComponent } from './faq/faq-questions/faq-questions.component';
import { CmsContactComponent } from './cms-contact/cms-contact.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { UsersComponent } from './user/users/users.component';
import { HotelsListComponent } from './hotels/hotels-list/hotels-list.component';
import { RoomsListComponent } from './rooms/rooms-list/rooms-list.component';
import { RoomTypeListComponent } from './room-types/room-type-list/room-type-list.component';
import { BookingsListComponent } from './bookings/bookings-list/bookings-list.component';
import { HotelsEditComponent } from './hotels/hotels-edit/hotels-edit.component';
import { HotelCreateComponent } from './hotels/hotel-create/hotel-create.component';
import { RoomsEditComponent } from './rooms/rooms-edit/rooms-edit.component';
import { BookingViewComponent } from './bookings/booking-view/booking-view.component';
import { TestComponent } from './test/test.component';
import { CmsListComponent } from './cms/cms-list/cms-list.component';
import { CmsEditComponent } from './cms/cms-edit/cms-edit.component';
import { HotelFeaturesListComponent } from './hotel-features/hotel-features-list/hotel-features-list.component';
import { ProductGroupsComponent } from './product/product-groups/product-groups.component';
import { ProductsComponent } from './product/products/products.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { PrebookedComponent } from './prebooked/prebooked.component';
import { PackagesListComponent } from './packages/packages-list/packages-list.component';
import { PackagesEditComponent } from './packages/packages-edit/packages-edit.component';
import { PendingChangesGuard } from './services/guards/pending-changes.guard';
import { RateGroupsListComponent } from './rate-groups/rate-groups-list/rate-groups-list.component';
import { RateGroupEditComponent } from './rate-groups/rate-group-edit/rate-group-edit.component';
import { PassportsComponent } from './passport/passports/passports.component';
import { PassportComponent } from './passport/passport/passport.component';
import { SignupsComponent } from './passport/signups/signups.component';
import { ReprintsComponent } from './passport/reprints/reprints.component';
import { UnconfirmedComponent } from './passport/unconfirmed/unconfirmed.component';
import { StaffComponent } from './passport/staff/staff.component';
import { SuppliersComponent } from './passport/suppliers/suppliers.component';
import { PassportStatsComponent } from './passport/passport-stats/passport-stats.component';
import { FaqTopicsComponent } from './faq/faq-topics/faq-topics.component';
import { EventBookingsComponent } from './events/event-bookings/event-bookings.component';
import { EventBookingComponent } from './events/event-booking/event-booking.component';
import { EventTypesComponent } from './events/event-types/event-types.component';
import { EventAttendeesComponent } from './events/event-attendees/event-attendees.component';
import { RrApiComponent } from './rr-api/rr-api.component';
import { ProductReportComponent } from './product-report/product-report.component';
import { GiftcardProductsComponent } from './giftcard/giftcard-products/giftcard-products.component';
import { RatePlansComponent } from './rate-plan/rate-plans/rate-plans.component';
import { EditRatePlanComponent } from './rate-plan/edit-rate-plan/edit-rate-plan.component';
import { ProductGroupEditComponent } from './product/product-group-edit/product-group-edit.component';

const routes: Routes = [
    { path: '', component: LoginComponent, pathMatch: 'full' },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: DashboardComponent,
        pathMatch: 'full'
    },
    {
        path: 'site-down',
        canActivate: [AuthGuard],
        component: SiteDownComponent,
        pathMatch: 'full'
    },
    {
        path: 'rr-sync',
        canActivate: [AuthGuard],
        component: RrApiComponent,
        pathMatch: 'full'
    },
    {
        path: 'giftcards',
        canActivate: [AuthGuard],
        component: GiftcardSearchComponent,
        pathMatch: 'full'
    },
    {
        path: 'giftcards/products',
        canActivate: [AuthGuard],
        component: GiftcardProductsComponent,
        pathMatch: 'full'
    },
    {
        path: 'reporting',
        canActivate: [AuthGuard],
        component: ReportingComponent,
        pathMatch: 'full'
    },
    {
        path: 'rateplans',
        canActivate: [AuthGuard],
        component: RatePlansComponent,
        pathMatch: 'full'
    },
    {
        path: 'rateplans/:id',
        canActivate: [AuthGuard],
        component: EditRatePlanComponent,
        pathMatch: 'full'
    },

    {
        path: 'vouchers',
        canActivate: [AuthGuard],
        component: VouchersComponent,
        pathMatch: 'full'
    },
    {
        path: 'vouchers/edit/:id',
        canActivate: [AuthGuard],
        component: VoucherEditComponent,
        pathMatch: 'full'
    },
    {
        path: 'vouchers/:id',
        canActivate: [AuthGuard],
        component: VoucherUsersComponent,
        pathMatch: 'full'
    },
    {
        path: 'events/attendees/:id',
        canActivate: [AuthGuard],
        component: EventAttendeesComponent,
        pathMatch: 'full'
    },

    {
        path: 'events/types',
        canActivate: [AuthGuard],
        component: EventTypesComponent,
        pathMatch: 'full'
    },
    {
        path: 'events/types/:id',
        canActivate: [AuthGuard],
        component: EventTypeComponent,
        pathMatch: 'full'
    },

    {
        path: 'events/bookings',
        canActivate: [AuthGuard],
        component: EventBookingsComponent,
        pathMatch: 'full'
    },
    {
        path: 'events/bookings/:id',
        canActivate: [AuthGuard],
        component: EventBookingComponent,
        pathMatch: 'full'
    },

    {
        path: 'events',
        canActivate: [AuthGuard],
        component: EventsComponent,
        pathMatch: 'full'
    },
    {
        path: 'events/:id',
        canActivate: [AuthGuard],
        component: EventComponent,
        pathMatch: 'full'
    },

    {
        path: 'bookings',
        canActivate: [AuthGuard],
        component: BookingsListComponent,
        pathMatch: 'full'
    },
    {
        path: 'bookings/:id',
        canActivate: [AuthGuard],
        component: BookingViewComponent,
        pathMatch: 'full'
    },
    {
        path: 'customer/:id',
        canActivate: [AuthGuard],
        component: CustomerViewComponent,
        pathMatch: 'full'
    },

    {
        path: 'faq',
        canActivate: [AuthGuard],
        component: FaqQuestionsComponent,
        pathMatch: 'full'
    },
    {
        path: 'contact',
        canActivate: [AuthGuard],
        component: CmsContactComponent,
        pathMatch: 'full'
    },

    {
        path: 'hotels',
        canActivate: [AuthGuard],
        component: HotelsListComponent,
        pathMatch: 'full'
    },
    {
        path: 'hotels/edit',
        canActivate: [AuthGuard],
        component: HotelsEditComponent,
        pathMatch: 'full'
    },
    {
        path: 'hotels/create',
        canActivate: [AuthGuard],
        component: HotelCreateComponent,
        pathMatch: 'full'
    },
    {
        path: 'hotels/features',
        canActivate: [AuthGuard],
        component: HotelFeaturesListComponent,
        pathMatch: 'full'
    },
    {
        path: 'rooms',
        canActivate: [AuthGuard],
        component: RoomsListComponent,
        pathMatch: 'full'
    },
    {
        path: 'rooms/edit',
        canActivate: [AuthGuard],
        component: RoomsEditComponent,
        pathMatch: 'full'
    },
    {
        path: 'room-types',
        canActivate: [AuthGuard],
        component: RoomTypeListComponent,
        pathMatch: 'full'
    },
    {
        path: 'rategroups',
        canActivate: [AuthGuard],
        component: RateGroupsListComponent,
        pathMatch: 'full'
    },
    {
        path: 'rategroup/edit/:Id',
        canActivate: [AuthGuard],
        component: RateGroupEditComponent,
        pathMatch: 'full'
    },
    {
        path: 'packages',
        canActivate: [AuthGuard],
        component: PackagesListComponent,
        pathMatch: 'full'
    },
    {
        path: 'packages/edit/:Id',
        canActivate: [AuthGuard],
        component: PackagesEditComponent,
        pathMatch: 'full',
    },

    {
        path: 'users',
        canActivate: [AuthGuard],
        component: UsersComponent,
        pathMatch: 'full'
    },
    {
        path: 'cms',
        canActivate: [AuthGuard],
        component: CmsListComponent,
        pathMatch: 'full'
    },
    {
        path: 'cms/:id',
        canActivate: [AuthGuard],
        component: CmsEditComponent,
        pathMatch: 'full'
    },
    {
        path: 'product-groups',
        canActivate: [AuthGuard],
        component: ProductGroupsComponent,
        pathMatch: 'full'
    },
    {
        path: 'product-groups/report',
        canActivate: [AuthGuard],
        component: ProductReportComponent,
        pathMatch: 'full'
    },
    {
        path: 'product-groups/:id/:mode',
        canActivate: [AuthGuard],
        component: ProductGroupEditComponent,
        pathMatch: 'full'
    },
    {
        path: 'product-groups/:id/products',
        canActivate: [AuthGuard],
        component: ProductsComponent,
        pathMatch: 'full'
    },
    {
        path: 'test',
        canActivate: [AuthGuard],
        component: TestComponent,
        pathMatch: 'full'
    },
    {
        path: 'testimonials',
        canActivate: [AuthGuard],
        component: TestimonialComponent,
        pathMatch: 'full'
    },
    {
        path: 'passports',
        canActivate: [AuthGuard],
        component: PassportsComponent,
        pathMatch: 'full'
    },
    {
        path: 'passports/signups',
        canActivate: [AuthGuard],
        component: SignupsComponent,
        pathMatch: 'full'
    },
    {
        path: 'passports/reprints',
        canActivate: [AuthGuard],
        component: ReprintsComponent,
        pathMatch: 'full'
    },
    {
        path: 'passports/unconfirmed',
        canActivate: [AuthGuard],
        component: UnconfirmedComponent,
        pathMatch: 'full'
    },
    {
        path: 'passports/staff',
        canActivate: [AuthGuard],
        component: StaffComponent,
        pathMatch: 'full'
    },
    {
        path: 'passports/suppliers',
        canActivate: [AuthGuard],
        component: SuppliersComponent,
        pathMatch: 'full'
    },
    {
        path: 'passports/stats',
        canActivate: [AuthGuard],
        component: PassportStatsComponent,
        pathMatch: 'full'
    },
    {
        path: 'passport/:id',
        canActivate: [AuthGuard],
        component: PassportComponent,
        pathMatch: 'full'
    },
    {
        path: 'feedback/stats/cluster',
        canActivate: [AuthGuard],
        component: FeedbackStatsClusterComponent,
        pathMatch: 'full'
    },
    {
        path: 'feedback/stats/daily',
        canActivate: [AuthGuard],
        component: FeedbackStatsDailyComponent,
        pathMatch: 'full'
    },
    {
        path: 'feedback/stats/weekly',
        canActivate: [AuthGuard],
        component: FeedbackStatsWeekComponent,
        pathMatch: 'full'
    },
    {
        path: 'feedback/stats/quarterly',
        canActivate: [AuthGuard],
        component: FeedbackStatsQuarterComponent,
        pathMatch: 'full'
    },

    {
        path: 'feedback/business',
        canActivate: [AuthGuard],
        component: FeedbackBusinessComponent,
        pathMatch: 'full'
    },
    {
        path: 'feedback/business/:id',
        canActivate: [AuthGuard],
        component: FeedbackBusinessDistroComponent,
        pathMatch: 'full'
    },

    {
        path: 'feedback/invites',
        canActivate: [AuthGuard],
        component: FeedbackInvitesComponent,
        pathMatch: 'full'
    },
    {
        path: 'feedback/answers',
        canActivate: [AuthGuard],
        component: FeedbackAnswersComponent,
        pathMatch: 'full'
    },
    {
        path: 'feedback/questions',
        canActivate: [AuthGuard],
        component: FeedbackQuestionsComponent,
        pathMatch: 'full'
    },
    {
        path: 'feedback/templates',
        canActivate: [AuthGuard],
        component: FeedbackTemplatesComponent,
        pathMatch: 'full'
    },
    {
        path: 'feedback/templates/:templateId/questions',
        canActivate: [AuthGuard],
        component: FeedbackQuestionsComponent,
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
