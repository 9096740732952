import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "../store";
import * as i3 from "@angular/common/http";
var GiftCardService = /** @class */ (function () {
    function GiftCardService(apiService, store, http) {
        this.apiService = apiService;
        this.store = store;
        this.http = http;
        this.env = environment;
    }
    GiftCardService.prototype.giftCardsByUser = function (userId) {
        return this.http.get(this.env.apiV2Path + 'gift-card/orders/user/' + userId)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    GiftCardService.prototype.getAllProducts = function () {
        return this.http.get(this.env.apiV2Path + 'gift-card/product')
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    GiftCardService.prototype.searchProducts = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'gift-card/product/search', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    GiftCardService.prototype.deleteProduct = function (id) {
        return this.http.delete(this.env.apiV2Path + 'gift-card/product/' + id)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    GiftCardService.prototype.createGiftCardProduct = function (product) {
        var dataToSend = {
            product: product,
        };
        return this.http.post(this.env.apiV2Path + 'gift-card/product', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    GiftCardService.prototype.assignGiftCardToDataSelection = function (params, giftCard) {
        var dataToSend = {
            params: params,
            giftCard: giftCard
        };
        return this.http.post(this.env.apiV2Path + 'gift-card/assign/data', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    GiftCardService.ngInjectableDef = i0.defineInjectable({ factory: function GiftCardService_Factory() { return new GiftCardService(i0.inject(i1.ApiService), i0.inject(i2.Store), i0.inject(i3.HttpClient)); }, token: GiftCardService, providedIn: "root" });
    return GiftCardService;
}());
export { GiftCardService };
