import { AuthenticationService } from './authentication.service';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '../store';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./authentication.service";
import * as i3 from "./api.service";
import * as i4 from "../store";
var ImagesService = /** @class */ (function () {
    function ImagesService(http, authService, apiService, store) {
        this.http = http;
        this.authService = authService;
        this.apiService = apiService;
        this.store = store;
        this.pagination = {
            searchTerm: '',
            fileType: '',
            limit: 25,
            limits: [5, 10, 25, 50],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'docs.friendlyName',
            sorts: [
                { ID: 'docs.friendlyName ASC', name: 'Name A-Z' },
                { ID: 'docs.friendlyName DESC', name: 'Name Z-A' },
                { ID: 'docs.created ASC', name: 'Created Oldest' },
                { ID: 'docs.created DESC', name: 'Created Newest' }
            ]
        };
    }
    ImagesService.prototype.getPagination = function () {
        return this.pagination;
    };
    ImagesService.prototype.setPagination = function (field, value) {
        this.pagination[field] = value;
    };
    ImagesService.prototype.get = function (type, ownerID) {
        var user = this.store.selectForLocal('user');
        var dataToSend = {
            user: user,
            type: type,
            ownerID: ownerID,
            pagination: this.pagination
        };
        return this.http.post(this.apiService.path() + 'images/binary', dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    ImagesService.prototype.delete = function (image) {
        return this.http.post(this.apiService.path() + 'images/delete', image)
            .pipe(map(function (data) {
            return data;
        }));
    };
    ImagesService.prototype.crop = function (image) {
        return this.http.post(this.apiService.path() + 'images/crop', image)
            .pipe(map(function (data) {
            return data;
        }));
    };
    ImagesService.prototype.updateSortOrder = function (images) {
        var dataToSend = {
            images: images
        };
        return this.http.post(this.apiService.path() + 'images/sort', dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    ImagesService.ngInjectableDef = i0.defineInjectable({ factory: function ImagesService_Factory() { return new ImagesService(i0.inject(i1.HttpClient), i0.inject(i2.AuthenticationService), i0.inject(i3.ApiService), i0.inject(i4.Store)); }, token: ImagesService, providedIn: "root" });
    return ImagesService;
}());
export { ImagesService };
