/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback-business.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./feedback-business.component";
import * as i7 from "../../services/feedback.service";
var styles_FeedbackBusinessComponent = [i0.styles];
var RenderType_FeedbackBusinessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackBusinessComponent, data: {} });
export { RenderType_FeedbackBusinessComponent as RenderType_FeedbackBusinessComponent };
function View_FeedbackBusinessComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-card", [["class", "no-records mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵted(-1, 0, [" No Brands Listed "]))], null, null); }
function View_FeedbackBusinessComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "https://feedback.norfolkpassport.com/assets/images/small_thumbsup_gold.png"], ["style", "height: 20px"]], null, null, null, null, null))], null, null); }
function View_FeedbackBusinessComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "https://feedback.norfolkpassport.com/assets/images/small_thumbsup_green.png"], ["style", "height: 20px"]], null, null, null, null, null))], null, null); }
function View_FeedbackBusinessComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "https://feedback.norfolkpassport.com/assets/images/small_thumbsup_red.png"], ["style", "height: 20px"]], null, null, null, null, null))], null, null); }
function View_FeedbackBusinessComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "hover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [["class", "hover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, [" ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 8, "td", [["class", "text-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", "%"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackBusinessComponent_4)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackBusinessComponent_5)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackBusinessComponent_6)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "td", [["class", "text-right"]], null, null, null, null, null))], function (_ck, _v) { var currVal_3 = (_v.context.$implicit.thumbs_percent >= 98); _ck(_v, 9, 0, currVal_3); var currVal_4 = ((_v.context.$implicit.thumbs_percent < 98) && (_v.context.$implicit.thumbs_percent >= 95)); _ck(_v, 11, 0, currVal_4); var currVal_5 = (_v.context.$implicit.thumbs_percent < 95); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.email_from; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.thumbs_percent; _ck(_v, 7, 0, currVal_2); }); }
function View_FeedbackBusinessComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "margin-top-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "table", [["class", "mat-elevation-z2 example-full-width data-listing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Brand name"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email from"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Feedback score"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackBusinessComponent_3)), i1.ɵdid(14, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.brands; _ck(_v, 14, 0, currVal_0); }, null); }
export function View_FeedbackBusinessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-card", [["class", "breadcrumb mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(-1, null, ["Brands"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "col-md-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackBusinessComponent_1)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackBusinessComponent_2)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 6, 0, "/feedback/business"); _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.brands.length; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.brands.length; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_FeedbackBusinessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback-business", [], null, null, null, View_FeedbackBusinessComponent_0, RenderType_FeedbackBusinessComponent)), i1.ɵdid(1, 114688, null, 0, i6.FeedbackBusinessComponent, [i7.FeedbackService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackBusinessComponentNgFactory = i1.ɵccf("app-feedback-business", i6.FeedbackBusinessComponent, View_FeedbackBusinessComponent_Host_0, {}, {}, []);
export { FeedbackBusinessComponentNgFactory as FeedbackBusinessComponentNgFactory };
