/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart-assessment-scores.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "./chart-assessment-scores.component";
import * as i5 from "../../services/chart.service";
import * as i6 from "../../store";
var styles_ChartAssessmentScoresComponent = [i0.styles];
var RenderType_ChartAssessmentScoresComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartAssessmentScoresComponent, data: {} });
export { RenderType_ChartAssessmentScoresComponent as RenderType_ChartAssessmentScoresComponent };
export function View_ChartAssessmentScoresComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h4", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancellations vs Bookings"])), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "div", [["style", "height: 350px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "canvas", [["id", "assessmentScores"]], null, null, null, null, null))], null, null); }
export function View_ChartAssessmentScoresComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chart-assessment-scores", [], null, null, null, View_ChartAssessmentScoresComponent_0, RenderType_ChartAssessmentScoresComponent)), i1.ɵdid(1, 114688, null, 0, i4.ChartAssessmentScoresComponent, [i5.ChartService, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartAssessmentScoresComponentNgFactory = i1.ɵccf("app-chart-assessment-scores", i4.ChartAssessmentScoresComponent, View_ChartAssessmentScoresComponent_Host_0, {}, {}, []);
export { ChartAssessmentScoresComponentNgFactory as ChartAssessmentScoresComponentNgFactory };
