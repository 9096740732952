import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { map } from 'rxjs/operators';
import { GrowlerService } from './growler.service';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
import * as i4 from "./growler.service";
var RoomService = /** @class */ (function () {
    function RoomService(http, apiService, store, growlerService) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.growlerService = growlerService;
        this.env = environment;
        this.defaultParams = {
            searchTerm: '',
            name: '',
            hotel: '',
            limit: 10,
            limits: [10, 20, 30, 40],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'room.name ASC',
            sorts: [
                { ID: 'room.name ASC', name: 'Hotel Name A-Z' },
                { ID: 'room.name DESC', name: 'Hotel Name Z-A' },
                { ID: 'hotel.name DESC', name: 'Hotel A-Z' },
                { ID: 'hotel.name ASC', name: 'Hotel Z-A' },
            ],
        };
    }
    RoomService.prototype.updateParams = function (params) {
        this.store.set('roomParams', params);
        this.search();
    };
    RoomService.prototype.resetParams = function () {
        this.store.set('roomParams', JSON.parse(JSON.stringify(this.defaultParams)));
        console.log(JSON.parse(JSON.stringify(this.defaultParams)));
        this.search();
    };
    RoomService.prototype.addSupplement = function (supplement) {
        var dataToSend = {
            supplement: supplement
        };
        return this.http.post(this.apiService.path() + 'supplement/create', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    RoomService.prototype.deleteSupplement = function (supplement) {
        var dataToSend = {
            supplement: supplement
        };
        return this.http.post(this.apiService.path() + 'supplement/delete', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    RoomService.prototype.setRoom = function (room) {
        var data = JSON.parse(JSON.stringify(this.store.selectForLocal('rooms')));
        for (var i = 0; i < data.length; i++) {
            if (data[i].id === room.id) {
                data[i] = room;
            }
        }
        this.store.set('room', room);
        this.store.set('rooms', data);
    };
    RoomService.prototype.save = function (room) {
        var data = this.store.selectForLocal('rooms');
        var foundRecord = false;
        for (var i = 0; i < data.length; i++) {
            if (data[i].id === room.id) {
                data[i] = room;
                foundRecord = true;
            }
        }
        this.store.set('rooms', data);
        this.saveRemote(room);
    };
    RoomService.prototype.create = function (room) {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            room: room
        };
        return this.http.post(this.apiService.path() + 'room/create', dataToSend).subscribe(function (data) {
            if (data.errors.length) {
                _this.growlerService.error(data.errors);
            }
            else {
                _this.setRoom(data.data);
                _this.growlerService.notification(['Room Details Added']);
            }
        });
    };
    RoomService.prototype.saveRemote = function (room) {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            room: room
        };
        return this.http.post(this.apiService.path() + 'room', dataToSend).subscribe(function (data) {
            if (data.errors.length) {
                _this.growlerService.error(data.errors);
            }
            else {
                _this.setRoom(data.data);
                _this.growlerService.notification(['Room Details Updated']);
            }
        });
    };
    RoomService.prototype.search = function () {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('roomParams')
        };
        return this.http.post(this.apiService.path() + 'room/search', dataToSend).subscribe(function (data) {
            _this.store.set('rooms', data.data);
            var p = _this.store.selectForLocal('roomParams');
            p.pages = Math.ceil(data.params.totalRecords / p.limit);
            p.totalRecords = data.params.totalRecords;
            p.pageArray = [];
            for (var i = 0; i < p.pages; i++) {
                p.pageArray.push(i);
            }
            _this.store.set('roomParams', p);
        });
    };
    RoomService.prototype.byHotelID = function (hotelID) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            hotelID: hotelID
        };
        return this.http.post(this.apiService.path() + 'room/byhotel', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    RoomService.prototype.roomFeaturesByHotel = function (hotelID) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            hotelID: hotelID
        };
        return this.http.post(this.apiService.path() + 'hotel/room/features', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    RoomService.prototype.setRoomFeatures = function (roomID, featureID) {
        var dataToSend = {
            roomID: roomID,
            featureID: featureID
        };
        return this.http.post(this.apiService.path() + 'hotel/room/features/set', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    RoomService.prototype.addRoomFeature = function (feature) {
        var dataToSend = {
            name: feature.name,
        };
        return this.http.post(this.apiService.path() + 'room/feature/add', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    RoomService.prototype.roomTypes = function () {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
        };
        return this.http.post(this.apiService.path() + 'room-types/all', dataToSend).subscribe(function (data) {
            _this.store.set('roomTypes', data.data);
        });
    };
    RoomService.prototype.allRooms = function () {
        return this.http.get(this.env.apiV2Path + 'room').pipe(map(function (data) {
            return data.data;
        }));
    };
    RoomService.prototype.addRoomType = function (roomType) {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            roomType: roomType
        };
        return this.http.post(this.apiService.path() + 'room-type', dataToSend).pipe(map(function (data) {
            _this.roomTypes();
            return data;
        }));
    };
    RoomService.prototype.deleteRoomType = function (roomType) {
        var _this = this;
        var dataToSend = {
            id: roomType.id
        };
        return this.http.post(this.apiService.path() + 'room-type/delete', dataToSend).pipe(map(function (data) {
            _this.roomTypes();
            return data;
        }));
    };
    RoomService.ngInjectableDef = i0.defineInjectable({ factory: function RoomService_Factory() { return new RoomService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store), i0.inject(i4.GrowlerService)); }, token: RoomService, providedIn: "root" });
    return RoomService;
}());
export { RoomService };
