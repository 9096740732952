/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../charts/chart-pp-spend/chart-pp-spend.component.ngfactory";
import * as i3 from "../charts/chart-pp-spend/chart-pp-spend.component";
import * as i4 from "../services/chart.service";
import * as i5 from "../store";
import * as i6 from "@angular/common";
import * as i7 from "../charts/chart-assessment-scores/chart-assessment-scores.component.ngfactory";
import * as i8 from "../charts/chart-assessment-scores/chart-assessment-scores.component";
import * as i9 from "./dashboard.component";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-md-6 margin-bottom-30"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-chart-pp-spend", [], null, null, null, i2.View_ChartPpSpendComponent_0, i2.RenderType_ChartPpSpendComponent)), i1.ɵdid(3, 114688, null, 0, i3.ChartPpSpendComponent, [i4.ChartService, i5.Store], { chartData: [0, "chartData"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-md-6 margin-bottom-30"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-chart-assessment-scores", [], null, null, null, i7.View_ChartAssessmentScoresComponent_0, i7.RenderType_ChartAssessmentScoresComponent)), i1.ɵdid(7, 114688, null, 0, i8.ChartAssessmentScoresComponent, [i4.ChartService, i5.Store], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "col-md-4 margin-bottom-30"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "col-md-4 margin-bottom-30"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "col-md-4 margin-bottom-30"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.chartData$)).schoolYear; _ck(_v, 3, 0, currVal_0); _ck(_v, 7, 0); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 114688, null, 0, i9.DashboardComponent, [i5.Store, i4.ChartService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i9.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
