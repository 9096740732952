/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hotel-features-sort.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-dragula";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "./hotel-features-sort.component";
import * as i7 from "../../services/hotel.service";
import * as i8 from "../../store";
import * as i9 from "../../services/overlay.service";
var styles_HotelFeaturesSortComponent = [i0.styles];
var RenderType_HotelFeaturesSortComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HotelFeaturesSortComponent, data: {} });
export { RenderType_HotelFeaturesSortComponent as RenderType_HotelFeaturesSortComponent };
function View_HotelFeaturesSortComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "drag-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-grip-lines"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_HotelFeaturesSortComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ul", [["dragula", "featureGroups"], ["style", "padding-left: 0"]], null, [[null, "dragulaModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragulaModelChange" === en)) {
        var pd_0 = ((_co.featureGroups = $event) !== false);
        ad = (pd_0 && ad);
    } if (("dragulaModelChange" === en)) {
        var pd_1 = (_co.drop($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DragulaDirective, [i1.ElementRef, i2.DragulaService], { dragula: [0, "dragula"], dragulaModel: [1, "dragulaModel"] }, { dragulaModelChange: "dragulaModelChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HotelFeaturesSortComponent_2)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "featureGroups"; var currVal_1 = _co.featureGroups; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.featureGroups; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_HotelFeaturesSortComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "aside"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "mat-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sort Feature Groups"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HotelFeaturesSortComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_1 = _co.featureGroups.length; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); }); }
export function View_HotelFeaturesSortComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hotel-features-sort", [], null, null, null, View_HotelFeaturesSortComponent_0, RenderType_HotelFeaturesSortComponent)), i1.ɵdid(1, 114688, null, 0, i6.HotelFeaturesSortComponent, [i7.HotelService, i8.Store, i9.OverlayService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HotelFeaturesSortComponentNgFactory = i1.ɵccf("app-hotel-features-sort", i6.HotelFeaturesSortComponent, View_HotelFeaturesSortComponent_Host_0, {}, {}, []);
export { HotelFeaturesSortComponentNgFactory as HotelFeaturesSortComponentNgFactory };
