import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
var CacheService = /** @class */ (function () {
    function CacheService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
        this.env = environment;
    }
    CacheService.prototype.clearCache = function () {
        return this.http.delete(this.apiService.v2Path() + 'data/cache').pipe(map(function (data) {
            return data;
        }));
    };
    CacheService.ngInjectableDef = i0.defineInjectable({ factory: function CacheService_Factory() { return new CacheService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: CacheService, providedIn: "root" });
    return CacheService;
}());
export { CacheService };
