import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
import * as i4 from "./growler.service";
var PackageService = /** @class */ (function () {
    function PackageService(http, apiService, store, growlerService) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.growlerService = growlerService;
        this.defaultParams = {
            searchTerm: '',
            active: 1,
            name: '',
            county: '',
            limit: 10,
            limits: [10, 20, 30, 40],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'packages_group.name ASC',
            sorts: [
                { ID: 'packages_group.name ASC', name: 'Promotion name A-Z' },
                { ID: 'packages_group.name DESC', name: 'Promotion name Z-A' },
                { ID: 'hotel.name ASC', name: 'Hotel name A-Z' },
                { ID: 'hotel.name DESC', name: 'Hotel name Z-A' },
                { ID: 'packages_group.created ASC', name: 'Created ASC' },
                { ID: 'packages_group.created DESC', name: 'Created DESC' },
            ],
        };
    }
    PackageService.prototype.updateParams = function (params) {
    };
    PackageService.prototype.resetParams = function () {
        return this.defaultParams;
    };
    PackageService.prototype.search = function (params) {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            params: params
        };
        return this.http.post(this.apiService.path() + 'packages/search', dataToSend).pipe(map(function (data) {
            console.log(data.data);
            _this.store.set('packages', data.data);
            return data;
            // this.store.set('packages', data.data);
            // const p = this.store.selectForLocal('packageParams');
            // p.pages = Math.ceil(data.params.totalRecords / p.limit);
            // p.totalRecords = data.params.totalRecords;
            // p.pageArray = [];
            // for (let i = 0; i < p.pages; i++) {
            //     p.pageArray.push(i);
            // }
            // this.store.set('packageParams', p);
        }));
    };
    PackageService.prototype.get = function (Id) {
        return this.http.get(this.apiService.path() + 'packages/get/' + Id).pipe(map(function (data) {
            return data.data;
        }));
    };
    PackageService.prototype.save = function (data) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            package: data
        };
        return this.http.post(this.apiService.path() + 'packages/update', dataToSend).pipe(map(function (response) {
            return response;
        }));
    };
    PackageService.prototype.updateActive = function (data) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            package: data
        };
        return this.http.post(this.apiService.path() + 'packages/update/active', dataToSend).pipe(map(function (response) {
            return response;
        }));
    };
    PackageService.prototype.packageDelete = function (packageData) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            package: packageData
        };
        return this.http.post(this.apiService.path() + 'packages/delete', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    PackageService.prototype.getRooms = function (hotel, packagedData) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            hotel: hotel,
            package: packagedData
        };
        return this.http.post(this.apiService.path() + 'packages/rooms', dataToSend)
            .pipe(map(function (response) {
            return response;
        }));
    };
    PackageService.prototype.toggleRoom = function (hotelId, roomId, packageId) {
        var dataToSend = {
            hotelId: hotelId,
            roomId: roomId,
            packageId: packageId
        };
        return this.http.post(this.apiService.path() + 'packages/room/toggle', dataToSend)
            .pipe(map(function (response) {
            return response;
        }));
    };
    PackageService.ngInjectableDef = i0.defineInjectable({ factory: function PackageService_Factory() { return new PackageService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store), i0.inject(i4.GrowlerService)); }, token: PackageService, providedIn: "root" });
    return PackageService;
}());
export { PackageService };
