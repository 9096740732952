import { Store } from "../store";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";
import { HttpClient } from "@angular/common/http";
import { GrowlerService } from "./growler.service";
import { OverlayService } from "./overlay.service";
import { map } from "rxjs/operators";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./authentication.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../store";
import * as i5 from "./overlay.service";
import * as i6 from "./growler.service";
var UserService = /** @class */ (function () {
    function UserService(apiService, authenticationService, http, store, overlayService, growlerService) {
        this.apiService = apiService;
        this.authenticationService = authenticationService;
        this.http = http;
        this.store = store;
        this.overlayService = overlayService;
        this.growlerService = growlerService;
        this.env = environment;
        this.params = {
            searchTerm: "",
            status: 1,
            limit: 32,
            limits: [8, 16, 24, 32],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: "user.firstname",
            sorts: [
                { ID: "user.firstname", name: "Name A-Z" },
                { ID: "user.firstname DESC", name: "Name Z-A" },
                { ID: "user.created DESC", name: "Created Descending" },
                { ID: "user.created", name: "Created Ascending" },
                { ID: "user.lastLogin DESC", name: "Last Login Descending" },
                { ID: "user.lastLogin", name: "Last Login Ascending" },
                { ID: "user.status", name: "Status Active" },
                { ID: "user.status DESC", name: "Status Inactive" },
            ],
        };
    }
    UserService.prototype.loadUsers = function (params) {
        var _this = this;
        var dataToSend = {
            params: params,
            user: this.store.selectForLocal("user"),
        };
        return this.http
            .post(this.apiService.path() + "users", dataToSend)
            .subscribe(function (data) {
            _this.store.set("users", data.data);
        });
    };
    UserService.prototype.loadFeedback = function (cardNumber) {
        return this.http
            .get(this.env.apiV2Path + "feedback/user/responses/" + cardNumber)
            .pipe(map(function (data) {
            return data;
        }));
    };
    UserService.prototype.searchFeedback = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http
            .post(this.env.apiV2Path + "feedback/user/responses", dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    UserService.prototype.loadTransactions = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http
            .post(this.env.apiV2Path + "users/en/transactions", dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    UserService.prototype.loadTransactionItems = function (id) {
        return this.http
            .get(this.env.apiV2Path + "users/en/transactionItems/" + id)
            .pipe(map(function (data) {
            return data;
        }));
    };
    UserService.prototype.create = function (user) {
        var _this = this;
        var dataToSend = {
            adminUser: this.store.selectForLocal("user"),
            user: user,
        };
        return this.http
            .post(this.apiService.path() + "users/create", dataToSend)
            .pipe(map(function (data) {
            if (data.status === 200) {
                var u = _this.store.selectForLocal("users");
                user.id = data.data.id;
                u.push(user);
                _this.store.set("users", u);
            }
            return data;
        }));
    };
    UserService.prototype.update = function (user) {
        var u = this.store.selectForLocal("users");
        for (var i = 0; i < u.length; i++) {
            if (u[i].id === user.id) {
                u[i] = user;
            }
        }
        this.store.set("users", u);
        var dataToSend = {
            adminUser: this.store.selectForLocal("user"),
            user: user,
        };
        return this.http
            .post(this.apiService.path() + "users/update", dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    UserService.prototype.updatePassword = function (data) {
        var dataToSend = {
            data: data,
        };
        return this.http
            .post(this.apiService.path() + "users/updatePassword", dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    UserService.prototype.loadPermissions = function () {
        return this.http.get(this.apiService.path() + "users/permissions").pipe(map(function (data) {
            return data.data;
        }));
    };
    UserService.prototype.delete = function (user) {
        var dataToSend = {
            user: user
        };
        return this.http.post(this.apiService.path() + "users/delete", dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.ApiService), i0.inject(i2.AuthenticationService), i0.inject(i3.HttpClient), i0.inject(i4.Store), i0.inject(i5.OverlayService), i0.inject(i6.GrowlerService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
