import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
import * as i4 from "./growler.service";
var CmsService = /** @class */ (function () {
    function CmsService(http, apiService, store, growlerService) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.growlerService = growlerService;
        this.env = environment;
        this.snippetTypes = [
            { id: 'title', name: 'Section title' },
            { id: 'text', name: 'Text Block' },
            { id: 'image', name: 'Image Block' },
            { id: 'wysiwyg', name: 'Editable Content' },
        ];
        this.fontStyles = ['normal', 'italic'];
        this.fontWeights = ['lighter', 'normal', 'bold'];
        this.fontColours = ['black', 'white', 'red', 'blue', 'green', 'orange'];
    }
    CmsService.prototype.getSnippetTypes = function () {
        return this.snippetTypes;
    };
    CmsService.prototype.getStyles = function () {
        return this.fontStyles;
    };
    CmsService.prototype.getWeights = function () {
        return this.fontWeights;
    };
    CmsService.prototype.getColours = function () {
        return this.fontColours;
    };
    CmsService.prototype.getAll = function () {
        return this.http.get(this.apiService.path() + 'cms/all').pipe(map(function (data) {
            return data;
        }));
    };
    CmsService.prototype.getByID = function (id) {
        return this.http.get(this.apiService.path() + 'cms/' + id).pipe(map(function (data) {
            return data;
        }));
    };
    CmsService.prototype.create = function (cmsData) {
        var _this = this;
        var dataToSend = {
            data: cmsData,
        };
        return this.http.post(this.apiService.path() + 'cms/create', dataToSend).pipe(map(function (data) {
            _this.growlerService.notification(['CMS Snippet Created']);
            return data;
        }));
    };
    CmsService.prototype.update = function (cmsData) {
        var _this = this;
        var dataToSend = {
            data: cmsData,
        };
        return this.http.post(this.apiService.path() + 'cms/update', dataToSend).pipe(map(function (data) {
            _this.growlerService.notification(['CMS Snippet Updated']);
            return data;
        }));
    };
    CmsService.prototype.getContactTile = function () {
        return this.http.get(this.env.apiV2Path + 'cms/contact').pipe(map(function (data) {
            return data;
        }));
    };
    CmsService.prototype.updateContactTile = function (contact) {
        var _this = this;
        var dataToSend = {
            contact: contact,
        };
        return this.http.post(this.env.apiV2Path + 'cms/contact', dataToSend).pipe(map(function (data) {
            _this.growlerService.notification(['CMS Updated']);
            return data;
        }));
    };
    CmsService.ngInjectableDef = i0.defineInjectable({ factory: function CmsService_Factory() { return new CmsService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store), i0.inject(i4.GrowlerService)); }, token: CmsService, providedIn: "root" });
    return CmsService;
}());
export { CmsService };
