<section class="margin-top-50">
    <form fxLayout="column" fxLayoutAlign="center center" #f="ngForm" (ngSubmit)="onSubmit(f)">
        <mat-card>
            <mat-card-content>
                <mat-form-field class="example-full-width">
                    <input type="email" matInput placeholder="Enter Email Address" ngModel name="email" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <input matInput type="password" placeholder="Enter Password" ngModel name="password" />
                </mat-form-field>
                <button type="submit" mat-raised-button color="primary">Login</button>
            </mat-card-content>
        </mat-card>
    </form>
    
</section>


