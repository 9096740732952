import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
import * as i4 from "./growler.service";
var ProductService = /** @class */ (function () {
    function ProductService(http, apiService, store, growlerService) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.growlerService = growlerService;
    }
    ProductService.prototype.groupsGet = function () {
        var _this = this;
        var dataToSend = {
            data: {}
        };
        return this.http.post(this.apiService.path() + '/product/groups', dataToSend).subscribe(function (data) {
            _this.store.set('productGroups', data.data);
        });
    };
    ProductService.prototype.groupsGetByHotel = function (hotels) {
        var _this = this;
        var dataToSend = {
            data: { hotels: hotels }
        };
        return this.http.post(this.apiService.path() + '/product/groups', dataToSend).subscribe(function (data) {
            _this.store.set('productGroups', data.data);
        });
    };
    ProductService.prototype.groupGet = function (id) {
        var dataToSend = {
            id: id
        };
        return this.http.post(this.apiService.path() + '/product/group', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    ProductService.prototype.productsGet = function (product_group) {
        var dataToSend = {
            product_group: product_group
        };
        return this.http.post(this.apiService.path() + '/products', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    ProductService.prototype.productGet = function (product) {
        var _this = this;
        var dataToSend = {
            id: product.ID
        };
        return this.http.post(this.apiService.path() + '/product', dataToSend).subscribe(function (data) {
            _this.productsGet(product.product_group);
            return data.data;
        });
    };
    ProductService.prototype.sortProducts = function (products) {
        var dataToSend = {
            products: products
        };
        return this.http.post(this.apiService.path() + 'products/sort', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }));
    };
    ProductService.prototype.groupAdd = function (product_group) {
        var _this = this;
        var dataToSend = {
            product_group: product_group
        };
        return this.http.post(this.apiService.path() + '/product/group/add', dataToSend).pipe(map(function (data) {
            _this.groupsGet();
            return data.data;
        }));
    };
    ProductService.prototype.groupUpdate = function (product_group) {
        var dataToSend = {
            product_group: product_group
        };
        return this.http.post(this.apiService.path() + '/product/group/update', dataToSend).pipe(map(function (data) {
            // this.groupsGet();
            return data.data;
        }));
    };
    ProductService.prototype.groupDelete = function (product_group) {
        var dataToSend = {
            id: product_group.ID
        };
        return this.http.post(this.apiService.path() + '/product/group/delete', dataToSend).pipe(map(function (data) {
            // this.groupsGet();
            return data.data;
        }));
    };
    ProductService.prototype.productAdd = function (product) {
        var _this = this;
        var dataToSend = {
            product: product
        };
        return this.http.post(this.apiService.path() + '/product/add', dataToSend).pipe(map(function (data) {
            _this.productsGet(product.product_group.ID);
            return data.data;
        }));
    };
    ProductService.prototype.productUpdate = function (product) {
        var dataToSend = {
            product: product
        };
        return this.http.post(this.apiService.path() + '/product/update', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    ProductService.prototype.productDelete = function (product) {
        var _this = this;
        var dataToSend = {
            id: product.ID
        };
        return this.http.post(this.apiService.path() + '/product/delete', dataToSend).pipe(map(function (data) {
            _this.productsGet(product.product_group.ID);
            return data.data;
        }));
    };
    ProductService.prototype.getIndexedProducts = function (hotelID) {
        var dataToSend = {
            hotelID: hotelID
        };
        return this.http.post(this.apiService.path() + '/hotel/products/indexed', dataToSend).pipe(map(function (data) {
            console.log(data);
            return data;
        }));
    };
    ProductService.prototype.toggleProduct = function (roomId, product) {
        var dataToSend = {
            productId: product.id,
            roomId: roomId,
            selected: product.selected
        };
        return this.http.post(this.apiService.path() + '/room/product/index', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    ProductService.ngInjectableDef = i0.defineInjectable({ factory: function ProductService_Factory() { return new ProductService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store), i0.inject(i4.GrowlerService)); }, token: ProductService, providedIn: "root" });
    return ProductService;
}());
export { ProductService };
