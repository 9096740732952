var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { VoucherService } from './../services/voucher.service';
import { ReportingService } from './../services/reporting.service';
import { Router } from '@angular/router';
import { GrowlerService } from './../services/growler.service';
import { OverlayService } from './../services/overlay.service';
import { Store } from './../store';
import { OnInit } from '@angular/core';
import { debounce } from 'lodash';
import { HotelService } from '../services/hotel.service';
import { GiftCardService } from '../services/gift-card.service';
var ReportingComponent = /** @class */ (function () {
    function ReportingComponent(store, overlayService, growlerService, router, reportingService, voucherService, giftcardService, hotelService) {
        this.store = store;
        this.overlayService = overlayService;
        this.growlerService = growlerService;
        this.router = router;
        this.reportingService = reportingService;
        this.voucherService = voucherService;
        this.giftcardService = giftcardService;
        this.hotelService = hotelService;
        this.hotels$ = this.store.select('hotels');
        this.segments = [];
        this.segmentSelected = undefined;
        this.searchRunRequired = false;
        this.searchWasRun = false;
        this.exportType = '';
        this.hotels = [];
        this.companies = [];
        this.spendParams = false;
        this.bookingParams = false;
        this.feedbackParams = false;
        this.passportParams = true;
        this.loading = false;
        this.showVoucherWindow = false;
        this.showGiftCardWindow = false;
        this.showGiftCardCreateWindow = false;
        this.recordsSelected = 0;
        this.yesNo = [{ id: '', name: 'Please Select' }, { id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }];
        this.params = {
            activeInCM: 99,
            passportType: '',
            excludeList: [],
            spendProduct: '',
            spend: { min: 0, max: 99999 },
            trans: { min: 0, max: 99999 },
            avgSpend: { min: 0, max: 99999 },
            passportDate: { from: undefined, to: undefined },
            bookingDate: { from: undefined, to: undefined },
            checkInDate: { from: undefined, to: undefined },
            spendDate: { from: undefined, to: undefined },
            hasStayed: 0,
            hasLeftFeedback: 0,
            hotel: '',
            hotels: [],
            spendHotels: [],
            companies: [],
            room: '',
            county: '',
            postcode: '',
            name: '',
            email: '',
            surname: '',
            type: "all",
            feedback: '',
            feedbackPerc: { min: 0, max: 99999 },
            bookings: 0,
            confirmed: 0,
            guest: 0,
            printed: true,
            staffGroup: 0,
            isSupplier: false,
            reprint: false,
            signUp: false,
            searchText: '',
            limit: 100,
            limits: [50, 100, 250],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: "users.createdAt DESC",
            sorts: [
                { ID: "users.eposNowId DESC", name: "ID First" },
                { ID: "users.eposNowId ASC", name: "ID Last" },
                { ID: "users.createdAt DESC", name: "Recent First" },
                { ID: "users.createdAt ASC", name: "Recent Last" },
                { ID: "users.firstname ASC", name: "Name A-Z" },
                { ID: "users.firstname DESC", name: "Name Z-A" },
                { ID: "users.feedback_qt DESC", name: "By feedback score" },
            ],
        };
        this.users = [];
        this.user$ = this.store.select('user');
        this.asideOpen$ = this.store.select('asideOpen');
        this.overlays$ = this.store.select('overlays');
        this.recordSelected = [];
        this.exportConfirmationData = {
            type: '',
            open: false,
            title: '',
            detail: '',
            data: undefined,
        };
        this.filterSearch = debounce(this.filterSearch, 450);
    }
    ReportingComponent.prototype.ngOnInit = function () {
        this.getHotels();
        this.getSegments();
        this.getCompanies();
        // this.filterSearch();
    };
    ReportingComponent.prototype.getCompanies = function () {
        var _this = this;
        this.reportingService.getCompanies().subscribe(function (data) {
            _this.companies = data;
        });
    };
    ReportingComponent.prototype.isCompanySelected = function (companyId) {
        if (!this.params.companies) {
            this.params.companies = [];
        }
        return this.params.companies.indexOf(companyId);
    };
    ReportingComponent.prototype.toggleCompany = function (companyId) {
        if (this.isCompanySelected(companyId) < 0) {
            this.params.companies.push(companyId);
        }
        else {
            this.params.companies.splice(this.isCompanySelected(companyId), 1);
        }
        this.filterSearch();
    };
    ReportingComponent.prototype.getHotels = function () {
        var _this = this;
        this.hotelService.allLite().subscribe(function (data) {
            _this.hotels = data;
        });
    };
    ReportingComponent.prototype.isHotelSelected = function (hotelId) {
        if (!this.params.hotels) {
            this.params.hotels = [];
        }
        return this.params.hotels.indexOf(hotelId);
    };
    ReportingComponent.prototype.toggleHotel = function (hotelId) {
        if (this.isHotelSelected(hotelId) < 0) {
            this.params.hotels.push(hotelId);
        }
        else {
            this.params.hotels.splice(this.isHotelSelected(hotelId), 1);
        }
        this.filterSearch();
    };
    ReportingComponent.prototype.isSpendHotelSelected = function (hotelId) {
        if (!this.params.spendHotels) {
            this.params.spendHotels = [];
        }
        return this.params.spendHotels.indexOf(hotelId);
    };
    ReportingComponent.prototype.toggleSpendHotel = function (hotelId) {
        if (this.isSpendHotelSelected(hotelId) < 0) {
            this.params.spendHotels.push(hotelId);
        }
        else {
            this.params.spendHotels.splice(this.isSpendHotelSelected(hotelId), 1);
        }
        this.filterSearch();
    };
    ReportingComponent.prototype.getSegments = function () {
        var _this = this;
        this.reportingService.getSegments().subscribe(function (data) {
            _this.segments = data;
        });
    };
    ReportingComponent.prototype.applyTemplate = function () {
        console.log(this.segmentSelected);
        if (this.segmentSelected) {
            this.params = __assign({}, this.segmentSelected.params);
        }
        this.filterSearch();
    };
    ReportingComponent.prototype.setRecordSelected = function (Id) {
        if (this.recordSelected.indexOf(Id) > -1) {
            this.recordSelected.splice(this.recordSelected.indexOf(Id), 1);
        }
        else {
            this.recordSelected.push(Id);
        }
    };
    ReportingComponent.prototype.isRecordSelected = function (Id) {
        if (this.recordSelected.indexOf(Id) < 0) {
            return false;
        }
        else {
            return true;
        }
    };
    ReportingComponent.prototype.selectAll = function () {
        if (this.recordSelected.length === this.users.length) {
            this.recordSelected = [];
        }
        else if (!this.recordSelected.length) {
            this.recordSelected = [];
            for (var i = 0; i < this.users.length; i++) {
                this.recordSelected.push(this.users[i].Id);
            }
        }
        else {
            this.recordSelected = [];
        }
    };
    ReportingComponent.prototype.setSort = function (v) {
        this.params.sort = v;
        this.search();
    };
    ReportingComponent.prototype.setParam = function (name, value) {
        this.params[name] = '';
        this.search();
    };
    ReportingComponent.prototype.filterSearch = function () {
        this.params.page = 0;
        this.searchRunRequired = true;
        this.searchWasRun = false;
        // this.search();
    };
    ReportingComponent.prototype.search = function () {
        var _this = this;
        this.loading = true;
        this.reportingService.search(this.params).subscribe(function (data) {
            console.log(data.data);
            _this.users = data.data;
            _this.searchRunRequired = false;
            _this.searchWasRun = true;
            _this.params.pages = Math.ceil(data.totalRecords / _this.params.limit);
            _this.params.totalRecords = data.totalRecords;
            _this.params.pageArray = [];
            for (var i = 0; i < _this.params.pages; i++) {
                _this.params.pageArray.push(i);
            }
            window.scroll(0, 0);
            _this.loading = false;
        });
    };
    ReportingComponent.prototype.setStatus = function (data) {
    };
    ReportingComponent.prototype.isOdd = function (num) {
        if (num % 2 === 1) {
            return false;
        }
        else {
            return true;
        }
    };
    ReportingComponent.prototype.processAction = function (e) {
        if (e.action === 'reload') {
            this.search();
        }
    };
    ReportingComponent.prototype.exportConfirm = function () {
        this.exportConfirmationData.type = this.exportType;
        if (this.exportType === 'voucher') {
            this.voucherAssign();
        }
        if (this.exportType === 'giftCard') {
            this.giftCardAssign();
        }
        if (this.exportType === 'export') {
            this.exportConfirmationData.title = 'Export Records';
            this.exportConfirmationData.data = '';
            this.exportConfirmationData.detail = 'Clicking confirm will create a CSV file and return a download link';
            this.exportConfirmationData.open = true;
        }
        if (this.exportType === 'sync') {
            this.exportConfirmationData.title = 'Sync to Campaign Monitor';
            this.exportConfirmationData.data = '';
            this.exportConfirmationData.detail = 'Clicking confirm will sync the data selection with Campaign Monitor';
            this.exportConfirmationData.open = true;
        }
        if (this.exportType === 'segment') {
            this.exportConfirmationData.title = 'Campaign Monitor Segment';
            this.exportConfirmationData.data = '';
            this.exportConfirmationData.detail = 'Clicking confirm will create a new segment in Campaign Monitor';
            this.exportConfirmationData.open = true;
        }
    };
    ReportingComponent.prototype.exportConfirmationComplete = function (e) {
        this.exportConfirmationData.open = false;
        if (e.action) {
        }
        else {
            console.log('cancelled');
        }
        this.getSegments();
        this.segmentSelected = undefined;
    };
    ReportingComponent.prototype.toggleSpendParams = function () {
        if (!this.spendParams) {
            this.spendParams = true;
        }
        else {
            this.spendParams = false;
        }
    };
    ReportingComponent.prototype.toggleBookingParams = function () {
        if (!this.bookingParams) {
            this.bookingParams = true;
        }
        else {
            this.bookingParams = false;
        }
    };
    ReportingComponent.prototype.togglePassportParams = function () {
        if (!this.passportParams) {
            this.passportParams = true;
        }
        else {
            this.passportParams = false;
        }
    };
    ReportingComponent.prototype.toggleFeedbackParams = function () {
        if (!this.feedbackParams) {
            this.feedbackParams = true;
        }
        else {
            this.feedbackParams = false;
        }
    };
    ReportingComponent.prototype.isExcluded = function (userId) {
        // console.log(userId.indexOf(this.params.excludeList))
        if (this.params.excludeList.indexOf(userId) < 0) {
            return false;
        }
        return true;
    };
    ReportingComponent.prototype.toggleExcluded = function (userId) {
        var pos = this.params.excludeList.indexOf(userId);
        console.log(userId, pos);
        if (pos < 0) {
            this.params.excludeList.push(userId);
        }
        else {
            this.params.excludeList.splice(pos, 1);
        }
        console.log(this.params.excludeList);
    };
    ReportingComponent.prototype.voucherAssign = function () {
        this.showVoucherWindow = true;
        this.recordsSelected = +this.params.totalRecords - this.params.excludeList.length;
    };
    ReportingComponent.prototype.voucherAssignAction = function (e) {
        var _this = this;
        console.log(e);
        if (e) {
            this.voucherService.assignVoucherToDataSelection(this.params, e.voucher).subscribe(function (data) {
                console.log(data);
                if (data.success) {
                    _this.growlerService.notification(['Vouchers have been assigned']);
                }
                else {
                    _this.growlerService.error(['Sorry - there was a problem assigning vouchers']);
                }
            });
        }
        this.showVoucherWindow = false;
    };
    ReportingComponent.prototype.giftCardAssign = function () {
        this.showGiftCardWindow = true;
        this.recordsSelected = +this.params.totalRecords - this.params.excludeList.length;
    };
    ReportingComponent.prototype.giftCardAssignAction = function (e) {
        var _this = this;
        console.log(e);
        if (e.action) {
            this.giftcardService.assignGiftCardToDataSelection(this.params, e.giftCard).subscribe(function (data) {
                console.log(data);
                if (data.success) {
                    _this.growlerService.notification(['Giftcards have been assigned']);
                }
                else {
                    _this.growlerService.error(['Sorry - there was a problem assigning giftcards']);
                }
            });
            this.showGiftCardWindow = false;
        }
        else {
            this.showGiftCardWindow = false;
            if (e.create) {
                this.showGiftCardCreateWindow = true;
            }
        }
        this.showGiftCardWindow = false;
    };
    ReportingComponent.prototype.giftCardProductCreated = function (e) {
        this.showGiftCardCreateWindow = false;
        if (e.action) {
            this.showGiftCardWindow = true;
        }
    };
    return ReportingComponent;
}());
export { ReportingComponent };
