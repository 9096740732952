/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-cropper.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i3 from "ngx-image-cropper";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./image-cropper.component";
import * as i12 from "../services/overlay.service";
import * as i13 from "../services/images.service";
var styles_ImageCropperComponent = [i0.styles];
var RenderType_ImageCropperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageCropperComponent, data: {} });
export { RenderType_ImageCropperComponent as RenderType_ImageCropperComponent };
function View_ImageCropperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-cropper", [], [[4, "text-align", null]], [[null, "imageCropped"], [null, "imageLoaded"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageCropped" === en)) {
        var pd_5 = (_co.imageCropped($event) !== false);
        ad = (pd_5 && ad);
    } if (("imageLoaded" === en)) {
        var pd_6 = (_co.imageLoaded() !== false);
        ad = (pd_6 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_7 = (_co.loadImageFailed() !== false);
        ad = (pd_7 && ad);
    } return ad; }, i2.View_ImageCropperComponent_0, i2.RenderType_ImageCropperComponent)), i1.ɵdid(1, 573440, null, 0, i3.ImageCropperComponent, [i4.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageChangedEvent: [0, "imageChangedEvent"], imageBase64: [1, "imageBase64"], maintainAspectRatio: [2, "maintainAspectRatio"], aspectRatio: [3, "aspectRatio"], resizeToWidth: [4, "resizeToWidth"], imageQuality: [5, "imageQuality"] }, { imageCropped: "imageCropped", imageLoaded: "imageLoaded", loadImageFailed: "loadImageFailed" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageChangedEvent; var currVal_2 = _co.image.imgBase; var currVal_3 = true; var currVal_4 = (6 / 4); var currVal_5 = 600; var currVal_6 = 100; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).alignImage; _ck(_v, 0, 0, currVal_0); }); }
export function View_ImageCropperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "full-screen-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "modal fade show"], ["id", "exampleModalCenter"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "modal-dialog modal-lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "container"], ["style", "padding: 80px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "row"], ["style", "margin: 0 auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageCropperComponent_1)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [["style", "max-width: 300px; margin-top: 5px"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "col-12 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Save Changes"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cropperReady; _ck(_v, 10, 0, currVal_0); var currVal_4 = "primary"; _ck(_v, 17, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.croppedImage; _ck(_v, 14, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 17).disabled || null); var currVal_3 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_2, currVal_3); }); }
export function View_ImageCropperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-cropper", [], null, null, null, View_ImageCropperComponent_0, RenderType_ImageCropperComponent)), i1.ɵdid(1, 114688, null, 0, i11.ImageCropperComponent, [i12.OverlayService, i13.ImagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageCropperComponentNgFactory = i1.ɵccf("app-image-cropper", i11.ImageCropperComponent, View_ImageCropperComponent_Host_0, { image: "image" }, { result: "result" }, []);
export { ImageCropperComponentNgFactory as ImageCropperComponentNgFactory };
