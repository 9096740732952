/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./images.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "./image-delete/image-delete.component.ngfactory";
import * as i11 from "./image-delete/image-delete.component";
import * as i12 from "../services/images.service";
import * as i13 from "../services/overlay.service";
import * as i14 from "./image-upload/image-upload.component.ngfactory";
import * as i15 from "./image-upload/image-upload.component";
import * as i16 from "../services/api.service";
import * as i17 from "ng2-dragula";
import * as i18 from "./images.component";
import * as i19 from "../store";
var styles_ImagesComponent = [i0.styles];
var RenderType_ImagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImagesComponent, data: {} });
export { RenderType_ImagesComponent as RenderType_ImagesComponent };
function View_ImagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.URL; _ck(_v, 0, 0, currVal_0); }); }
function View_ImagesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.imgBase; _ck(_v, 0, 0, currVal_0); }); }
function View_ImagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "li", [["class", "tile gallery-tile"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "small": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["color", "warn"], ["mat-mini-fab", ""], ["style", "position: absolute; top: 5px; left: 5px"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cropImage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["crop"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["color", "warn"], ["mat-mini-fab", ""], ["style", "position: absolute; top: 5px; right: 5px"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagesComponent_2)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagesComponent_3)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tile gallery-tile"; var currVal_1 = _ck(_v, 2, 0, _co.smallThumb); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = "warn"; _ck(_v, 4, 0, currVal_4); _ck(_v, 6, 0); var currVal_8 = "warn"; _ck(_v, 9, 0, currVal_8); _ck(_v, 11, 0); var currVal_10 = !_v.context.$implicit.imgBase; _ck(_v, 14, 0, currVal_10); var currVal_11 = _v.context.$implicit.imgBase; _ck(_v, 16, 0, currVal_11); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 4).disabled || null); var currVal_3 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 9).disabled || null); var currVal_7 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 11).inline; _ck(_v, 10, 0, currVal_9); }); }
function View_ImagesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-delete", [], null, [[null, "modalResponse"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modalResponse" === en)) {
        var pd_0 = (_co.deleteResponse($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ImageDeleteComponent_0, i10.RenderType_ImageDeleteComponent)), i1.ɵdid(1, 114688, null, 0, i11.ImageDeleteComponent, [i12.ImagesService, i13.OverlayService], { modalData: [0, "modalData"], modals: [1, "modals"] }, { modalResponse: "modalResponse" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalData; var currVal_1 = _co.modals; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ImagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "text-right margin-bottom-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-image-upload", [["class", "pull-right"]], null, [[null, "imageUploaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("imageUploaded" === en)) {
        var pd_0 = (_co.appendImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_ImageUploadComponent_0, i14.RenderType_ImageUploadComponent)), i1.ɵdid(2, 114688, null, 0, i15.ImageUploadComponent, [i12.ImagesService, i16.ApiService], { type: [0, "type"], ownerID: [1, "ownerID"] }, { imageUploaded: "imageUploaded" }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "ul", [["dragula", "roomImages"]], null, [[null, "dragulaModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragulaModelChange" === en)) {
        var pd_0 = ((_co.images = $event) !== false);
        ad = (pd_0 && ad);
    } if (("dragulaModelChange" === en)) {
        var pd_1 = (_co.drop($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i17.DragulaDirective, [i1.ElementRef, i17.DragulaService], { dragula: [0, "dragula"], dragulaModel: [1, "dragulaModel"] }, { dragulaModelChange: "dragulaModelChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagesComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [["class", "break: left;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ImagesComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.ownerID; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "roomImages"; var currVal_3 = _co.images; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = _co.images; _ck(_v, 7, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.overlays$)).imageDelete; _ck(_v, 10, 0, currVal_5); }, null); }
export function View_ImagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-images", [], null, null, null, View_ImagesComponent_0, RenderType_ImagesComponent)), i1.ɵdid(1, 114688, null, 0, i18.ImagesComponent, [i12.ImagesService, i13.OverlayService, i19.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImagesComponentNgFactory = i1.ɵccf("app-images", i18.ImagesComponent, View_ImagesComponent_Host_0, { allowSelection: "allowSelection", type: "type", ownerID: "ownerID", smallThumb: "smallThumb" }, { imageSelected: "imageSelected" }, []);
export { ImagesComponentNgFactory as ImagesComponentNgFactory };
