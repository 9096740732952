import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
import * as i4 from "./growler.service";
var PrebookService = /** @class */ (function () {
    function PrebookService(http, apiService, store, growlerService) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.growlerService = growlerService;
        this.defaultParams = {
            searchTerm: '',
            name: '',
            hotelID: '',
            limit: 20,
            limits: [20, 50, 75, 100],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'prebooking.created DESC',
            sorts: [
                { ID: 'prebooking.created DESC', name: 'Recent First' },
                { ID: 'prebooking.created ASC', name: 'Recent Last' },
                { ID: 'hotel.name ASC', name: 'Hotel Name A-Z' },
                { ID: 'hotel.name DESC', name: 'Hotel Name Z-A' },
            ],
        };
    }
    PrebookService.prototype.updateParams = function (params) {
        this.store.set('preBookedParams', params);
        this.searchPrebooked();
    };
    PrebookService.prototype.resetParams = function () {
        this.store.set('preBookedParams', JSON.parse(JSON.stringify(this.defaultParams)));
        this.searchPrebooked();
    };
    PrebookService.prototype.searchPrebooked = function () {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('preBookedParams')
        };
        return this.http.post(this.apiService.path() + 'prebookings/search', dataToSend).subscribe(function (data) {
            _this.store.set('prebookings', data.data);
            var p = _this.store.selectForLocal('preBookedParams');
            p.pages = Math.ceil(data.params.totalRecords / p.limit);
            p.totalRecords = data.params.totalRecords;
            p.pageArray = [];
            for (var i = 0; i < p.pages; i++) {
                p.pageArray.push(i);
            }
            _this.store.set('preBookedParams', p);
        });
    };
    PrebookService.prototype.togglePrebookedActioned = function (booking) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            booking: booking,
        };
        return this.http.post(this.apiService.path() + 'prebookings/actioned', dataToSend).subscribe(function (data) {
        });
    };
    PrebookService.prototype.groupsGet = function () {
        var _this = this;
        var dataToSend = {
            data: {}
        };
        return this.http.post(this.apiService.path() + '/prebook/groups', dataToSend).subscribe(function (data) {
            _this.store.set('productGroups', data.data);
        });
    };
    PrebookService.prototype.groupGetByHotel = function (id) {
        return this.http.get(this.apiService.path() + '/prebook/group/hotel/' + id).pipe(map(function (data) {
            return data.data;
        }));
    };
    PrebookService.prototype.groupAdd = function (product_group) {
        var _this = this;
        var dataToSend = {
            product_group: product_group
        };
        return this.http.post(this.apiService.path() + '/prebook/group/add', dataToSend).pipe(map(function (data) {
            _this.groupsGet();
            return data.data;
        }));
    };
    PrebookService.prototype.groupUpdate = function (product_group) {
        var _this = this;
        var dataToSend = {
            product_group: product_group
        };
        return this.http.post(this.apiService.path() + '/prebook/group/update', dataToSend).pipe(map(function (data) {
            _this.groupsGet();
            return data.data;
        }));
    };
    PrebookService.prototype.groupDelete = function (product_group) {
        var _this = this;
        var dataToSend = {
            id: product_group.ID
        };
        return this.http.post(this.apiService.path() + '/prebook/group/delete', dataToSend).pipe(map(function (data) {
            _this.groupsGet();
            return data.data;
        }));
    };
    PrebookService.prototype.itemAdd = function (item) {
        var dataToSend = {
            item: item
        };
        return this.http.post(this.apiService.path() + '/prebook/item/add', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    PrebookService.prototype.itemUpdate = function (item) {
        var dataToSend = {
            item: item
        };
        return this.http.post(this.apiService.path() + '/prebook/item/update', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    PrebookService.prototype.itemDelete = function (item) {
        var dataToSend = {
            id: item.id
        };
        return this.http.post(this.apiService.path() + '/prebook/item/delete', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    PrebookService.prototype.updateOverview = function (pb) {
        return this.http.post(this.apiService.path() + '/prebook/update', pb).pipe(map(function (data) {
            return data.data;
        }));
    };
    PrebookService.prototype.updateSortOrder = function (group) {
        var dataToSend = {
            group: group
        };
        return this.http.post(this.apiService.path() + '/prebook/item/sort', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    PrebookService.ngInjectableDef = i0.defineInjectable({ factory: function PrebookService_Factory() { return new PrebookService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store), i0.inject(i4.GrowlerService)); }, token: PrebookService, providedIn: "root" });
    return PrebookService;
}());
export { PrebookService };
