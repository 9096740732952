import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "../store";
import * as i3 from "@angular/common/http";
var RatePlanService = /** @class */ (function () {
    function RatePlanService(apiService, store, http) {
        this.apiService = apiService;
        this.store = store;
        this.http = http;
        this.env = environment;
    }
    RatePlanService.prototype.get = function (id) {
        return this.http.get(this.env.apiV2Path + 'rate-plan/' + id)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    RatePlanService.prototype.getAll = function () {
        return this.http.get(this.env.apiV2Path + 'rate-plan')
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    RatePlanService.prototype.getAllGrouped = function () {
        return this.http.get(this.env.apiV2Path + 'rate-plan/grouped')
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    RatePlanService.prototype.search = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'rate-plan/search', dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    RatePlanService.prototype.create = function (ratePlan) {
        var dataToSend = {
            ratePlan: ratePlan
        };
        return this.http.post(this.env.apiV2Path + 'rate-plan', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    RatePlanService.prototype.toggleStatus = function (ratePlan) {
        var dataToSend = {
            ratePlan: ratePlan
        };
        return this.http.post(this.env.apiV2Path + 'rate-plan/status', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    RatePlanService.prototype.update = function (ratePlan) {
        var dataToSend = {
            ratePlan: ratePlan
        };
        return this.http.put(this.env.apiV2Path + 'rate-plan', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    RatePlanService.prototype.delete = function (event) {
        return this.http.delete(this.env.apiV2Path + 'rate-plan/' + event.id)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    RatePlanService.prototype.sortSkus = function (skus) {
        var dataToSend = {
            skus: skus
        };
        return this.http.post(this.env.apiV2Path + 'rate-plan/sort', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    RatePlanService.ngInjectableDef = i0.defineInjectable({ factory: function RatePlanService_Factory() { return new RatePlanService(i0.inject(i1.ApiService), i0.inject(i2.Store), i0.inject(i3.HttpClient)); }, token: RatePlanService, providedIn: "root" });
    return RatePlanService;
}());
export { RatePlanService };
