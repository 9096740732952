<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-7">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/bookings']">Bookings</a>
        </div>
        <div class="col-md-5 text-right">

            <!-- <div class="d-flex" *ngIf="!(params$ | async).startDate || !(params$ | async).endDate">
                Select checkin dates to export
                <button [disabled]="true" type="button" class="ml-3 btn btn-success" (click)="exportConfirm()" matTooltip="Export to CSV file">
                    Export
                 </button>

            </div> -->
            <button *ngIf="(params$ | async).startDate || (params$ | async).endDate || (params$ | async).bookingStartDate || (params$ | async).bookingEndDate" type="button" class="btn btn-success" (click)="exportConfirm()" matTooltip="Export to CSV file">
               Export
            </button>

        </div>
    </div>
</mat-card>

<div class="alert alert-danger text-center" *ngIf="(rrBacklog$ | async)">
    <h5>FYI - Room Raccoon is currently syncing availability, if this message persists after 2-3 hours, please contact Craig</h5>
</div>

<mat-card class="text-right">
    <app-bookings-filter [params]="(params$ | async)"></app-bookings-filter>
</mat-card>

<mat-card class="no-records" *ngIf="!(bookings$ | async).length">
    No Bookings Listed
</mat-card>

<ng-container *ngIf="(bookings$ | async).length">

    <div class="margin-top-20">
        <app-pagination [params]="(params$ | async)" (update)="search()"></app-pagination>


        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <th>Created</th>
                <th>Checked?</th>
                <th>Rooms<br />Booked</th>
                <th>Customer / Room</th>
<!--                <th>Customer Telephone</th>-->
                <th>Check In</th>
                <th>Nights / Guests</th>
                <!-- <th>Adults / Children</th> -->
                <th>Value</th>
                <!-- <th>Depost Paid (&pound;)</th> -->
                <th>Extras?</th>
                <th>Treatments?</th>
				<th>Has notes?</th>
				<th>Promo</th>
				<th>RR</th>
                <!-- <th></th> -->
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let booking of (bookings$ | async)">
                <td class="hover" (click)="edit(booking)">{{booking.created | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td class="hover" (click)="markAsChecked(booking)">
                    <i class="fa fa-check text-success" *ngIf="booking.checked"></i>
                    <i class="fa fa-check text-danger" *ngIf="!booking.checked"></i>
                </td>

                <td class="hover" (click)="edit(booking)">{{booking.bookingItemCount}}</td>
                <td class="hover" (click)="edit(booking)">
                    {{booking.customer.firstname}} {{booking.customer.surname}}
                    <br />{{booking.hotel.name}} : {{booking.room.name}} ({{booking.ratePlan}})
                </td>
<!--                <td class="hover" (click)="edit(booking)"> {{booking.customer.telephone}}</td>-->
                <td class="hover" (click)="edit(booking)">{{booking.checkIn | date: 'dd/MM/yyyy'}}</td>
                <td class="hover" (click)="edit(booking)">
                    {{booking.nights}} night<span *ngIf="booking.nights > 1">s</span>
                    <br />
                    <span style="font-size: 10px;">{{booking.adults}} AD / {{booking.children}} CH</span>
                </td>
                <td class="hover" (click)="edit(booking)">
                    &pound;{{booking.payment.gross}}
                    <br />
                    <span style="font-size: 10px;">&pound;{{booking.payment.deposit}} paid</span>
                </td>
                <td class="hover" (click)="edit(booking)">
                    <i class="fa fa-check text-success" *ngIf="booking.hasExtras"></i>
                </td>
                <td class="hover" (click)="edit(booking)">
                    <i class="fa fa-check text-success" *ngIf="booking.trybeOrderId"></i>
                </td>
				<td class="hover" (click)="edit(booking)">
					<i class="far fa-comment" *ngIf="booking.comments"></i>
				</td>


                <!-- <td class="hover" (click)="edit(booking)">
                    <i class="fa fa-check text-success" *ngIf="booking.hasUpgrade"></i>
                </td> -->
				<td class="hover" (click)="edit(booking)">
                    <i class="fa fa-check text-success" *ngIf="booking.promoCode"></i>
                </td>
				<td class="hover" (click)="edit(booking)">
                    {{booking.rrId}}
                </td>
                <!-- <td class="text-right">
                    <button mat-icon-button (click)="edit(booking)">
                        <mat-icon>search</mat-icon>
                    </button>
                </td> -->
            </tr>
            </tbody>
        </table>

        <app-pagination [params]="(params$ | async)" (update)="search()"></app-pagination>
    </div>

    <app-confirm-export *ngIf="exportConfirmationData.open" [confirmationData]="exportConfirmationData" (complete)="exportConfirmationComplete($event)"></app-confirm-export>

</ng-container>
