import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
import * as i4 from "./growler.service";
var SettingsService = /** @class */ (function () {
    function SettingsService(http, apiService, store, growlerService) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.growlerService = growlerService;
        this.env = environment;
    }
    SettingsService.prototype.getSetting = function (id) {
        return this.http.get(this.env.apiV2Path + 'session/setting/' + id).pipe(map(function (data) {
            return data;
        }));
    };
    SettingsService.prototype.setSetting = function (id, val) {
        var dataToSend = { id: id, val: val };
        return this.http.put(this.env.apiV2Path + 'session/setting', dataToSend).pipe(map(function (data) {
            return data;
        }));
    };
    SettingsService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store), i0.inject(i4.GrowlerService)); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
export { SettingsService };
