import { Store } from '../store';
import * as i0 from "@angular/core";
import * as i1 from "../store";
var OverlayService = /** @class */ (function () {
    function OverlayService(store) {
        this.store = store;
    }
    OverlayService.prototype.open = function (v) {
        var o = this.store.selectForLocal('overlays');
        o[v] = true;
        this.store.set('overlays', o);
    };
    OverlayService.prototype.close = function (v) {
        var o = this.store.selectForLocal('overlays');
        o[v] = false;
        this.store.set('overlays', o);
        this.store.set('asideOpen', false);
    };
    OverlayService.prototype.closeAll = function () {
        var o = this.store.selectForLocal('overlays');
        var objKeys = Object.keys(o);
        console.log(objKeys);
        for (var i = 0; i < objKeys.length; i++) {
            o[objKeys[i]] = false;
        }
        this.store.set('overlays', o);
        this.store.set('asideOpen', false);
    };
    OverlayService.ngInjectableDef = i0.defineInjectable({ factory: function OverlayService_Factory() { return new OverlayService(i0.inject(i1.Store)); }, token: OverlayService, providedIn: "root" });
    return OverlayService;
}());
export { OverlayService };
