/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-upload.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-dropzone-wrapper";
import * as i3 from "./image-upload.component";
import * as i4 from "../../services/images.service";
import * as i5 from "../../services/api.service";
var styles_ImageUploadComponent = [i0.styles];
var RenderType_ImageUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageUploadComponent, data: {} });
export { RenderType_ImageUploadComponent as RenderType_ImageUploadComponent };
export function View_ImageUploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "dropzone dropzone-container"]], null, [[null, "error"], [null, "uploadprogress"], [null, "success"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onUploadError($event) !== false);
        ad = (pd_0 && ad);
    } if (("uploadprogress" === en)) {
        var pd_1 = (_co.uploadprogress($event) !== false);
        ad = (pd_1 && ad);
    } if (("success" === en)) {
        var pd_2 = (_co.onUploadSuccess($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 999424, null, 0, i2.DropzoneDirective, [i1.NgZone, i1.Renderer2, i1.ElementRef, i1.KeyValueDiffers, i1.PLATFORM_ID, [2, i2.DROPZONE_CONFIG]], { config: [0, "config"] }, { DZ_ERROR: "error", DZ_SUCCESS: "success" }), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "dz-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "dz-filename"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["data-dz-name", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "dz-size"], ["data-dz-size", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["data-dz-thumbnail", ""]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadConfig; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ImageUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-upload", [], null, null, null, View_ImageUploadComponent_0, RenderType_ImageUploadComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageUploadComponent, [i4.ImagesService, i5.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageUploadComponentNgFactory = i1.ɵccf("app-image-upload", i3.ImageUploadComponent, View_ImageUploadComponent_Host_0, { type: "type", ownerID: "ownerID", text: "text" }, { imageUploaded: "imageUploaded" }, []);
export { ImageUploadComponentNgFactory as ImageUploadComponentNgFactory };
