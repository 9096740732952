import { Store } from './../store';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
var TestimonialService = /** @class */ (function () {
    function TestimonialService(http, apiService, store) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.params = {
            searchTerm: '',
            customerEmail: '',
            limit: 20,
            limits: [20, 50, 75, 100],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'testimonial.created DESC',
            sorts: [
                { ID: 'testimonial.created ASC', name: 'Created Asc' },
                { ID: 'testimonial.created DESC', name: 'Created Desc' },
                { ID: 'testimonial.rating ASC', name: 'Rating Asc' },
                { ID: 'testimonial.rating DESC', name: 'Rating Desc' },
                { ID: 'testimonial.customerEmail DESC', name: 'Email A-Z' },
                { ID: 'testimonial.customerEmail ASC', name: 'Email Z-A' }
            ]
        };
    }
    TestimonialService.prototype.search = function (params) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            params: params
        };
        return this.http
            .post(this.apiService.path() + 'testimonial/search', dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    TestimonialService.ngInjectableDef = i0.defineInjectable({ factory: function TestimonialService_Factory() { return new TestimonialService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store)); }, token: TestimonialService, providedIn: "root" });
    return TestimonialService;
}());
export { TestimonialService };
