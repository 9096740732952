import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/api.service";
import * as i2 from "../store";
import * as i3 from "@angular/common/http";
var FaqService = /** @class */ (function () {
    function FaqService(apiService, store, http) {
        this.apiService = apiService;
        this.store = store;
        this.http = http;
        this.env = environment;
    }
    FaqService.prototype.getQuestions = function () {
        var _this = this;
        return this.http.get(this.env.apiV2Path + 'faq')
            .pipe(map(function (data) {
            _this.store.set('faqQuestions', data.data);
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    FaqService.prototype.findAllTopics = function () {
        var _this = this;
        var faq = this.store.selectForLocal('faq');
        var dataToSend = {
            params: faq.params,
        };
        return this.http.post(this.env.apiV2Path + 'faq/search', dataToSend)
            .pipe(map(function (data) {
            faq.data = data.data.data;
            faq.params.pages = Math.ceil(data.data.totalRecords / faq.params.limit);
            faq.params.totalRecords = data.data.totalRecords;
            faq.params.pageArray = [];
            for (var i = 0; i < faq.params.pages; i++) {
                faq.params.pageArray.push(i);
            }
            _this.store.set('faq', faq);
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FaqService.prototype.findOne = function (id) {
        return this.http.get(this.env.apiV2Path + 'faq/' + id)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FaqService.prototype.create = function (topic) {
        var user = this.store.selectForLocal('user');
        var dataToSend = {
            topic: topic,
            user: user,
        };
        return this.http.post(this.env.apiV2Path + 'faq', dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FaqService.prototype.update = function (topic) {
        var user = this.store.selectForLocal('user');
        var dataToSend = {
            topic: topic,
            user: user,
        };
        return this.http.put(this.env.apiV2Path + 'faq', dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FaqService.prototype.createQuestion = function (question) {
        var user = this.store.selectForLocal('user');
        var dataToSend = {
            question: question,
            user: user,
        };
        return this.http.post(this.env.apiV2Path + 'faq/question', dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FaqService.prototype.updateQuestion = function (question) {
        var user = this.store.selectForLocal('user');
        var dataToSend = {
            question: question,
            user: user,
        };
        return this.http.put(this.env.apiV2Path + 'faq/question', dataToSend)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    FaqService.prototype.sortTopics = function (topics) {
        var dataToSend = {
            topics: topics
        };
        return this.http.post(this.env.apiV2Path + 'faq/sort', dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    FaqService.prototype.sortQuestions = function (questions) {
        var dataToSend = {
            questions: questions
        };
        return this.http.post(this.env.apiV2Path + 'faq/questions/sort', dataToSend)
            .pipe(map(function (data) {
            return data;
        }));
    };
    FaqService.ngInjectableDef = i0.defineInjectable({ factory: function FaqService_Factory() { return new FaqService(i0.inject(i1.ApiService), i0.inject(i2.Store), i0.inject(i3.HttpClient)); }, token: FaqService, providedIn: "root" });
    return FaqService;
}());
export { FaqService };
