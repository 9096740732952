import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
import * as i4 from "./growler.service";
var BookingService = /** @class */ (function () {
    function BookingService(http, apiService, store, growlerService) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
        this.growlerService = growlerService;
        this.env = environment;
        this.defaultParams = {
            searchTerm: '',
            name: '',
            hotelID: '',
            upgraded: '',
            hasPromo: '',
            hasExtras: '',
            county: '',
            startDate: undefined,
            endDate: undefined,
            limit: 20,
            limits: [20, 50, 75, 100],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'booking.created DESC',
            sorts: [
                { ID: 'booking.created DESC', name: 'Recent First' },
                { ID: 'booking.created ASC', name: 'Recent Last' },
                { ID: 'hotel.name ASC', name: 'Hotel Name A-Z' },
                { ID: 'hotel.name DESC', name: 'Hotel Name Z-A' },
                { ID: 'county.name DESC', name: 'County A-Z' },
                { ID: 'county.name ASC', name: 'County Z-A' },
            ],
        };
    }
    BookingService.prototype.updateParams = function (params) {
        this.store.set('bookingParams', params);
        this.search();
    };
    BookingService.prototype.resetParams = function () {
        this.store.set('bookingParams', JSON.parse(JSON.stringify(this.defaultParams)));
        console.log(JSON.parse(JSON.stringify(this.defaultParams)));
        this.search();
    };
    BookingService.prototype.search = function () {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('bookingParams')
        };
        return this.http.post(this.apiService.path() + 'bookings/search', dataToSend).subscribe(function (data) {
            _this.store.set('bookings', data.data);
            var p = _this.store.selectForLocal('bookingParams');
            p.pages = Math.ceil(data.params.totalRecords / p.limit);
            p.totalRecords = data.params.totalRecords;
            p.pageArray = [];
            for (var i = 0; i < p.pages; i++) {
                p.pageArray.push(i);
            }
            _this.store.set('bookingParams', p);
            _this.store.set('rrBacklog', data.rrBacklog);
        });
    };
    BookingService.prototype.markAsChecked = function (bookingId) {
        return this.http.get(environment.apiV2Path + 'bookings/checked/' + bookingId).pipe(map(function (data) {
            return data;
        }));
    };
    BookingService.prototype.getSpaOrder = function (trybeOrderId) {
        return this.http.get(environment.apiV2Path + 'trybe/order/' + trybeOrderId).pipe(map(function (data) {
            return data;
        }));
    };
    BookingService.prototype.searchGiftCards = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'bookings/giftcard/transactions', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }));
    };
    BookingService.prototype.searchExtras = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'bookings/extras/transactions', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }));
    };
    BookingService.prototype.exportBookings = function () {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('bookingParams')
        };
        return this.http
            .post(this.apiService.path() + 'bookings/export', dataToSend, this.apiService.getHttpOptions())
            .pipe(map(function (data) {
            return data;
        }));
    };
    BookingService.prototype.byCustomer = function (customerId) {
        return this.http.get(this.apiService.path() + 'bookings/customer/' + customerId).pipe(map(function (data) {
            return data.data;
        }));
    };
    BookingService.prototype.setBooking = function (booking) {
        var data = JSON.parse(JSON.stringify(this.store.selectForLocal('bookings')));
        for (var i = 0; i < data.length; i++) {
            if (data[i].id === booking.id) {
                data[i] = booking;
            }
        }
        this.store.set('booking', booking);
        this.store.set('bookings', data);
    };
    BookingService.prototype.get = function (id) {
        var dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('bookingParams'),
            id: id
        };
        return this.http.post(this.apiService.path() + 'bookings/id', dataToSend).pipe(map(function (data) {
            return data.data;
        }));
    };
    BookingService.prototype.reconcile = function (booking) {
        return this.http.get(this.apiService.path() + 'bookings/reconcile/' + booking.id).subscribe(function (data) {
            return data.data;
        });
    };
    BookingService.prototype.resendGiftCardEmail = function (id) {
        return this.http.get(this.env.apiV2Path + 'bookings/giftcard/resend/' + id).pipe(map(function (data) {
            return data.data;
        }));
    };
    BookingService.ngInjectableDef = i0.defineInjectable({ factory: function BookingService_Factory() { return new BookingService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store), i0.inject(i4.GrowlerService)); }, token: BookingService, providedIn: "root" });
    return BookingService;
}());
export { BookingService };
