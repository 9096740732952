/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart-pp-spend.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "./chart-pp-spend.component";
import * as i5 from "../../services/chart.service";
import * as i6 from "../../store";
var styles_ChartPpSpendComponent = [i0.styles];
var RenderType_ChartPpSpendComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartPpSpendComponent, data: {} });
export { RenderType_ChartPpSpendComponent as RenderType_ChartPpSpendComponent };
export function View_ChartPpSpendComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h4", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Revenue vs Bookings"])), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "div", [["style", "height: 350px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "canvas", [["id", "ppSpend"]], null, null, null, null, null))], null, null); }
export function View_ChartPpSpendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chart-pp-spend", [], null, null, null, View_ChartPpSpendComponent_0, RenderType_ChartPpSpendComponent)), i1.ɵdid(1, 114688, null, 0, i4.ChartPpSpendComponent, [i5.ChartService, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartPpSpendComponentNgFactory = i1.ɵccf("app-chart-pp-spend", i4.ChartPpSpendComponent, View_ChartPpSpendComponent_Host_0, { chartData: "chartData" }, {}, []);
export { ChartPpSpendComponentNgFactory as ChartPpSpendComponentNgFactory };
