import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { Store } from './store';
import * as i0 from "@angular/core";
import * as i1 from "./services/authentication.service";
import * as i2 from "./store";
import * as i3 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, store, router) {
        this.authService = authService;
        this.store = store;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.authService.isAuthenticated()
            .then(function (authenticated) {
            if (authenticated) {
                return true;
            }
            else {
                _this.router.navigate(['']);
            }
        });
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AuthenticationService), i0.inject(i2.Store), i0.inject(i3.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
