/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback-stats-cluster.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./feedback-stats-cluster.component";
var styles_FeedbackStatsClusterComponent = [i0.styles];
var RenderType_FeedbackStatsClusterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackStatsClusterComponent, data: {} });
export { RenderType_FeedbackStatsClusterComponent as RenderType_FeedbackStatsClusterComponent };
export function View_FeedbackStatsClusterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" feedback-stats-cluster works!\n"]))], null, null); }
export function View_FeedbackStatsClusterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback-stats-cluster", [], null, null, null, View_FeedbackStatsClusterComponent_0, RenderType_FeedbackStatsClusterComponent)), i1.ɵdid(1, 114688, null, 0, i2.FeedbackStatsClusterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackStatsClusterComponentNgFactory = i1.ɵccf("app-feedback-stats-cluster", i2.FeedbackStatsClusterComponent, View_FeedbackStatsClusterComponent_Host_0, {}, {}, []);
export { FeedbackStatsClusterComponentNgFactory as FeedbackStatsClusterComponentNgFactory };
