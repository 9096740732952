/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback-templates.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "ng2-dragula";
import * as i5 from "../../confirmation/confirmation.component.ngfactory";
import * as i6 from "../../confirmation/confirmation.component";
import * as i7 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i8 from "@angular/material/card";
import * as i9 from "./feedback-templates.component";
import * as i10 from "../../services/overlay.service";
import * as i11 from "../../services/feedback.service";
import * as i12 from "../../store";
import * as i13 from "../../services/menu.service";
var styles_FeedbackTemplatesComponent = [i0.styles];
var RenderType_FeedbackTemplatesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackTemplatesComponent, data: {} });
export { RenderType_FeedbackTemplatesComponent as RenderType_FeedbackTemplatesComponent };
function View_FeedbackTemplatesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "no-results"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No templates found"]))], null, null); }
function View_FeedbackTemplatesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "li", [["class", "dd-item"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "dd-collapsed": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 21, "div", [["class", "dd-handle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "label label-info mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-bars"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["style", "margin-left: 10px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteConfirm(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-trash"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "ml-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editTemplate(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "a", [["class", "ml-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleStatus(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "i", [["class", "toggle fa"]], null, null, null, null, null)), i1.ɵdid(15, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { "fa-toggle-on text-success": 0, "fa-toggle-off": 1 }), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [["class", "float-right ml-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), (_l()(), i1.ɵeld(19, 0, null, null, 4, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(22, 3), (_l()(), i1.ɵted(-1, null, ["View questions"])), (_l()(), i1.ɵted(24, null, [" ", " - ", " "]))], function (_ck, _v) { var currVal_0 = "dd-item"; var currVal_1 = _ck(_v, 2, 0, !_v.context.$implicit.open); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "toggle fa"; var currVal_3 = _ck(_v, 16, 0, _v.context.$implicit.is_active, !_v.context.$implicit.is_active); _ck(_v, 15, 0, currVal_2, currVal_3); var currVal_7 = _ck(_v, 22, 0, "/feedback/templates", _v.context.$implicit.id, "questions"); _ck(_v, 21, 0, currVal_7); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.thumbs_percent; _ck(_v, 18, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 21).target; var currVal_6 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_5, currVal_6); var currVal_8 = _v.context.$implicit.name; var currVal_9 = _v.context.$implicit.invite_email_subject; _ck(_v, 24, 0, currVal_8, currVal_9); }); }
function View_FeedbackTemplatesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "dd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ol", [["class", "dd-list mt-2"], ["dragula", "priCategory"]], null, [[null, "dragulaModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragulaModelChange" === en)) {
        var pd_0 = ((_co.templates = $event) !== false);
        ad = (pd_0 && ad);
    } if (("dragulaModelChange" === en)) {
        var pd_1 = (_co.drop($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i4.DragulaDirective, [i1.ElementRef, i4.DragulaService], { dragula: [0, "dragula"], dragulaModel: [1, "dragulaModel"] }, { dragulaModelChange: "dragulaModelChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackTemplatesComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "priCategory"; var currVal_1 = _co.templates; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.templates; _ck(_v, 5, 0, currVal_2); }, null); }
function View_FeedbackTemplatesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation", [], null, [[null, "complete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("complete" === en)) {
        var pd_0 = (_co.confirmAction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ConfirmationComponent_0, i5.RenderType_ConfirmationComponent)), i1.ɵdid(1, 114688, null, 0, i6.ConfirmationComponent, [], { data: [0, "data"] }, { complete: "complete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmationData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FeedbackTemplatesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-card", [["class", "breadcrumb mat-card"]], null, null, null, i7.View_MatCard_0, i7.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i8.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(-1, null, ["Templates"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "col-md-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "data-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary-reverse btn-round btn-slim"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addTemplate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add new Template"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackTemplatesComponent_1)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackTemplatesComponent_2)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackTemplatesComponent_4)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 6, 0, "/feedback/templates"); _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.templates.length; _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.templates.length; _ck(_v, 16, 0, currVal_4); var currVal_5 = _co.confirmationData.open; _ck(_v, 18, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_FeedbackTemplatesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback-templates", [], null, null, null, View_FeedbackTemplatesComponent_0, RenderType_FeedbackTemplatesComponent)), i1.ɵdid(1, 114688, null, 0, i9.FeedbackTemplatesComponent, [i10.OverlayService, i11.FeedbackService, i12.Store, i3.Router, i3.ActivatedRoute, i13.MenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackTemplatesComponentNgFactory = i1.ɵccf("app-feedback-templates", i9.FeedbackTemplatesComponent, View_FeedbackTemplatesComponent_Host_0, {}, {}, []);
export { FeedbackTemplatesComponentNgFactory as FeedbackTemplatesComponentNgFactory };
