<div class="row">
    <div class="col-md-2">
        <mat-form-field class="text-left" style="width: 100%">
            <input matInput [(ngModel)]="params.name" (keyup)="updateParams()" placeholder="Search....">
        </mat-form-field>
    </div>
    <div class="col-md-2">
        <mat-form-field style="display: block">
            <mat-select
                [(ngModel)]="params.hotelID"
                (selectionChange)="updateParams()"
                placeholder="Hotel">
                <mat-option value="">All Hotels</mat-option>
                <mat-option
                    [value]="sy.id"
                    *ngFor="let sy of (hotels$ | async)">
                    {{sy.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-2">
        <mat-form-field style="display: block">
            <mat-select
                [(ngModel)]="params.ratePlanCode"
                (selectionChange)="updateParams()"
                placeholder="Hotel">
                <mat-option value="">All rate plans</mat-option>
                <mat-option
                    [value]="r.ratePlanCode"
                    *ngFor="let r of ratePlans">
                    {{r.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-md-2">
        <mat-form-field style="display: block">
            <mat-select
                [(ngModel)]="params.upgraded"
                (selectionChange)="updateParams()"
                placeholder="Has upgraded?">
                <mat-option value="">Has upgraded?</mat-option>
                <mat-option [value]="1">Yes</mat-option>
                <mat-option [value]="0">No</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
	<div class="col-md-2">
		<mat-form-field style="display: block">
			<mat-select
					[(ngModel)]="params.hasPromo"
					(selectionChange)="updateParams()"
					placeholder="Used promotion?">
				<mat-option value="">Used promotion?</mat-option>
				<mat-option [value]="1">Yes</mat-option>
				<mat-option [value]="0">No</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="col-md-2">
		<mat-form-field style="display: block">
			<mat-select
					[(ngModel)]="params.giftcard"
					(selectionChange)="updateParams()"
					placeholder="Used giftcard?">
				<mat-option value="">Used giftcard?</mat-option>
				<mat-option [value]="1">Yes</mat-option>
				<mat-option [value]="0">No</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

</div>
<div class="row">

    <div class="col-md-2">
		<mat-form-field style="display: block">
			<mat-select
					[(ngModel)]="params.hasExtras"
					(selectionChange)="updateParams()"
					placeholder="Has extras?">
				<mat-option value="">Has extras?</mat-option>
				<mat-option [value]="1">Yes</mat-option>
				<mat-option [value]="0">No</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-2">
		<mat-form-field style="display: block">
			<mat-select
					[(ngModel)]="params.hasSpa"
					(selectionChange)="updateParams()"
					placeholder="Has spa treatments?">
				<mat-option value="">Has spa treatments?</mat-option>
				<mat-option [value]="1">Yes</mat-option>
				<mat-option [value]="0">No</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

    <div class="col-md-2">
        <mat-form-field style="display: block">
            <input type="search" matInput (dateChange)="updateParams()" [(ngModel)]="startDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Check in from">
            <mat-datepicker #startpicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-md-2">
        <mat-form-field style="display: block">
            <input type="search" matInput (dateChange)="updateParams()" [(ngModel)]="endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="Check in to">
            <mat-datepicker #endpicker></mat-datepicker>
        </mat-form-field>
    </div>


    <div class="col-md-2">
        <mat-form-field style="display: block">
            <input type="search" matInput (dateChange)="updateParams()" [(ngModel)]="bookingStartDate" (click)="bookingStartpicker.open()" [matDatepicker]="bookingStartpicker" placeholder="Booking date from">
            <mat-datepicker #bookingStartpicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-md-2">
        <mat-form-field style="display: block">
            <input type="search" matInput (dateChange)="updateParams()" [(ngModel)]="bookingEndDate" (click)="bookingEndpicker.open()" [matDatepicker]="bookingEndpicker" placeholder="Booking date to">
            <mat-datepicker #bookingEndpicker></mat-datepicker>
        </mat-form-field>
    </div>

</div>
<div class="row">
    <div class="col-md-2">
        <mat-form-field style="display: block">
            <mat-select
                [(ngModel)]="params.sort"
                (selectionChange)="updateParams()"
                placeholder="Sort By">
                <mat-option
                    [value]="sort.ID"
                    *ngFor="let sort of params.sorts">
                    {{sort.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-md-2">
        <mat-form-field style="display: block">
            <mat-select [(ngModel)]="params.limit" (ngModelChange)="updateParams()" placeholder="Items per page">
                <mat-option
                    [value]="l"
                    *ngFor="let l of params.limits"
                >
                    {{ l }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-8 text-right">
        <a mat-raised-button color="warn" (click)="this.resetParams()">Clear</a>
    </div>

</div>



