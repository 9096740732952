/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./calendar.component";
var styles_CalendarComponent = [i0.styles];
var RenderType_CalendarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarComponent, data: {} });
export { RenderType_CalendarComponent as RenderType_CalendarComponent };
function View_CalendarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "calendar-holder-day-blank"]], null, null, null, null, null))], null, null); }
function View_CalendarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "calendar-holder-day"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setDay(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "today": 0, "date-selected": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "calendar-holder-day"; var currVal_1 = _ck(_v, 2, 0, (((_co.dateToOutput.y === _co.theYear) && (_co.dateToOutput.m === _co.theMonth)) && (_co.dateToOutput.d === _v.parent.context.$implicit)), _co.isDateSelected(_v.parent.context.$implicit, _co.theMonth, _co.theYear)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit; _ck(_v, 4, 0, currVal_2); }); }
function View_CalendarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit > 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CalendarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 30, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 29, "div", [["class", "calendar-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["style", "height: 30px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "top-bar-direction-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.monthBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "top-bar-date-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setView("month") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-caret-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "top-bar-date-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setView("year") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-caret-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "top-bar-direction-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.monthForward() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 14, "div", [["class", "day-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "calendar-holder-day-of-week"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["M"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "calendar-holder-day-of-week"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["T"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "calendar-holder-day-of-week"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["W"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["class", "calendar-holder-day-of-week"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["T"])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "div", [["class", "calendar-holder-day-of-week"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["F"])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "div", [["class", "calendar-holder-day-of-week"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["S"])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "div", [["class", "calendar-holder-day-of-week"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["S"])), (_l()(), i1.ɵeld(28, 0, null, null, 2, "div", [["style", "clear-after: both; height: 185px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarComponent_2)), i1.ɵdid(30, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.days; _ck(_v, 30, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.monthName; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.theYear; _ck(_v, 9, 0, currVal_1); }); }
function View_CalendarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setMonth(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "month-selector"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "cal-selected": 0 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "month-selector"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit === _co.theMonth)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.monthNames[_v.context.$implicit].name; _ck(_v, 4, 0, currVal_2); }); }
function View_CalendarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "calendar-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "btn-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setView("calendar") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row no-gutters margin-top-40"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarComponent_6)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.monthsArray; _ck(_v, 5, 0, currVal_0); }, null); }
function View_CalendarComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setYear(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "year-selector"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "cal-selected": 0 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "year-selector"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit === _co.theYear)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 4, 0, currVal_2); }); }
function View_CalendarComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "calendar-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "btn-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setView("calendar") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row no-gutters margin-top-40"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarComponent_8)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.yearsArray; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_CalendarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarComponent_7)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.view === "calendar"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.view === "month"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.view === "year"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_CalendarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendar", [], null, null, null, View_CalendarComponent_0, RenderType_CalendarComponent)), i1.ɵdid(1, 245760, null, 0, i3.CalendarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarComponentNgFactory = i1.ɵccf("app-calendar", i3.CalendarComponent, View_CalendarComponent_Host_0, { dateSelected: "dateSelected", datesSelected: "datesSelected" }, { dateWasSelected: "dateWasSelected", close: "close" }, []);
export { CalendarComponentNgFactory as CalendarComponentNgFactory };
