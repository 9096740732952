import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
import * as i3 from "../store";
var CountiesService = /** @class */ (function () {
    function CountiesService(http, apiService, store) {
        this.http = http;
        this.apiService = apiService;
        this.store = store;
    }
    CountiesService.prototype.all = function () {
        var _this = this;
        return this.http.get(this.apiService.path() + 'counties').subscribe(function (data) {
            _this.store.set('counties', data.data);
        });
    };
    CountiesService.ngInjectableDef = i0.defineInjectable({ factory: function CountiesService_Factory() { return new CountiesService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Store)); }, token: CountiesService, providedIn: "root" });
    return CountiesService;
}());
export { CountiesService };
