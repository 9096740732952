/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./room-features.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "../../../../node_modules/@angular/material/slide-toggle/typings/index.ngfactory";
import * as i5 from "@angular/material/slide-toggle";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/forms";
import * as i11 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "@angular/common";
import * as i14 from "./room-features.component";
import * as i15 from "../../services/overlay.service";
var styles_RoomFeaturesComponent = [i0.styles];
var RenderType_RoomFeaturesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RoomFeaturesComponent, data: {} });
export { RenderType_RoomFeaturesComponent as RenderType_RoomFeaturesComponent };
function View_RoomFeaturesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "mat-list-item", [["class", "mat-list-item"], ["role", "listitem"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1097728, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 2, 6, "mat-slide-toggle", [["class", "example-margin mat-slide-toggle"], ["color", "primary"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checked", null], [2, "mat-disabled", null], [2, "mat-slide-toggle-label-before", null], [2, "_mat-animation-noopable", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "focus"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_v.context.$implicit.selected = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatSlideToggle_0, i4.RenderType_MatSlideToggle)), i1.ɵdid(6, 1228800, null, 0, i5.MatSlideToggle, [i1.ElementRef, i6.Platform, i7.FocusMonitor, i1.ChangeDetectorRef, [8, null], i1.NgZone, i5.MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, [2, i8.ANIMATION_MODULE_TYPE], [2, i9.Directionality]], { color: [0, "color"] }, null), i1.ɵprd(1024, null, i10.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.MatSlideToggle]), i1.ɵdid(8, 671744, null, 0, i10.NgModel, [[8, null], [8, null], [8, null], [6, i10.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i10.NgControl, null, [i10.NgModel]), i1.ɵdid(10, 16384, null, 0, i10.NgControlStatus, [[4, i10.NgControl]], null, null), (_l()(), i1.ɵted(11, 0, [" ", " "]))], function (_ck, _v) { var currVal_15 = "primary"; _ck(_v, 6, 0, currVal_15); var currVal_16 = _v.context.$implicit.selected; _ck(_v, 8, 0, currVal_16); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 6).id; var currVal_3 = (0 - 1); var currVal_4 = i1.ɵnov(_v, 6).checked; var currVal_5 = i1.ɵnov(_v, 6).disabled; var currVal_6 = (i1.ɵnov(_v, 6).labelPosition == "before"); var currVal_7 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); var currVal_8 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 10).ngClassValid; var currVal_13 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 1, [currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); var currVal_17 = _v.context.$implicit.name; _ck(_v, 11, 0, currVal_17); }); }
export function View_RoomFeaturesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "aside"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null], [2, i12.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "mat-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Room Features"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "mat-list", [["class", "mat-list mat-list-base"], ["role", "list"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(8, 49152, null, 0, i3.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RoomFeaturesComponent_1)), i1.ɵdid(10, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_1 = _co.room.features; _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); }); }
export function View_RoomFeaturesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-room-features", [], null, null, null, View_RoomFeaturesComponent_0, RenderType_RoomFeaturesComponent)), i1.ɵdid(1, 114688, null, 0, i14.RoomFeaturesComponent, [i15.OverlayService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoomFeaturesComponentNgFactory = i1.ɵccf("app-room-features", i14.RoomFeaturesComponent, View_RoomFeaturesComponent_Host_0, { room: "room" }, {}, []);
export { RoomFeaturesComponentNgFactory as RoomFeaturesComponentNgFactory };
