/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./products.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/common";
import * as i5 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "@angular/router";
import * as i8 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "./products.component";
import * as i14 from "../../services/product.service";
import * as i15 from "../../services/overlay.service";
import * as i16 from "../../store";
var styles_ProductsComponent = [i0.styles];
var RenderType_ProductsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductsComponent, data: {} });
export { RenderType_ProductsComponent as RenderType_ProductsComponent };
function View_ProductsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-card", [["class", "no-records mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵted(-1, 0, [" No extras for this group\n"]))], null, null); }
function View_ProductsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"]))], null, null); }
function View_ProductsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No"]))], null, null); }
function View_ProductsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.question; _ck(_v, 1, 0, currVal_0); }); }
function View_ProductsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["style", "width: 40%;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [["style", "width: 120px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "td", [["style", "width: 120px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductsComponent_4)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductsComponent_5)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductsComponent_6)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 8, "td", [["class", "text-right"], ["style", "width: 120px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "a", [["class", "hover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.productUpdate(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(18, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["edit"])), (_l()(), i1.ɵeld(20, 0, null, null, 3, "a", [["class", "hover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.productDelete(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(22, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete"]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.inputRequired; _ck(_v, 9, 0, currVal_3); var currVal_4 = !_v.context.$implicit.inputRequired; _ck(_v, 11, 0, currVal_4); var currVal_5 = _v.context.$implicit.inputRequired; _ck(_v, 14, 0, currVal_5); _ck(_v, 18, 0); _ck(_v, 22, 0); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.terms; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.cost; _ck(_v, 6, 0, currVal_2); var currVal_6 = i1.ɵnov(_v, 18).inline; _ck(_v, 17, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 22).inline; _ck(_v, 21, 0, currVal_7); }); }
function View_ProductsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "margin-top-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "table", [["class", "mat-elevation-z2 example-full-width data-listing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Extra Name"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Extra Cost"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Text box?"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Question"])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProductsComponent_3)), i1.ɵdid(18, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform(_co.products$)); _ck(_v, 18, 0, currVal_0); }, null); }
export function View_ProductsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "mat-card", [["class", "breadcrumb mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(-1, null, ["Room extras"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_forward_ios"])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), (_l()(), i1.ɵted(-1, null, ["Extras"])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "col-md-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["color", "primary"], ["id", "pullright"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.productAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Add extra"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProductsComponent_1)), i1.ɵdid(20, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProductsComponent_2)), i1.ɵdid(23, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 6, 0, "/product-groups"); _ck(_v, 5, 0, currVal_2); _ck(_v, 9, 0); var currVal_6 = _ck(_v, 13, 0, "/products"); _ck(_v, 12, 0, currVal_6); var currVal_9 = "primary"; _ck(_v, 17, 0, currVal_9); var currVal_10 = !i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform(_co.products$)).length; _ck(_v, 20, 0, currVal_10); var currVal_11 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform(_co.products$)).length; _ck(_v, 23, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 9).inline; _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 12).target; var currVal_5 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_7 = (i1.ɵnov(_v, 17).disabled || null); var currVal_8 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_7, currVal_8); }); }
export function View_ProductsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-products", [], null, null, null, View_ProductsComponent_0, RenderType_ProductsComponent)), i1.ɵdid(1, 114688, null, 0, i13.ProductsComponent, [i14.ProductService, i15.OverlayService, i16.Store, i7.Router, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductsComponentNgFactory = i1.ɵccf("app-products", i13.ProductsComponent, View_ProductsComponent_Host_0, {}, {}, []);
export { ProductsComponentNgFactory as ProductsComponentNgFactory };
