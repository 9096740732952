import { Store } from '../store';
import * as i0 from "@angular/core";
import * as i1 from "../store";
var MenuService = /** @class */ (function () {
    function MenuService(store) {
        this.store = store;
    }
    MenuService.prototype.openMenu = function () {
        this.store.set('menuOpen', true);
    };
    MenuService.prototype.closeMenu = function () {
        this.store.set('menuOpen', false);
    };
    MenuService.prototype.toggleMenu = function () {
        var m = this.store.selectForLocal('menuOpen');
        if (m) {
            m = false;
        }
        else {
            m = true;
        }
        this.store.set('menuOpen', m);
    };
    MenuService.prototype.toggleHeaderMenu = function () {
        var m = this.store.selectForLocal('menuHeaderOpen');
        if (m) {
            m = false;
        }
        else {
            m = true;
        }
        this.store.set('menuHeaderOpen', m);
    };
    MenuService.ngInjectableDef = i0.defineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.inject(i1.Store)); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
