import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Store } from '../store';
import * as i0 from "@angular/core";
import * as i1 from "../store";
var ApiService = /** @class */ (function () {
    function ApiService(store) {
        this.store = store;
    }
    ApiService.prototype.path = function () {
        return 'https://bpt-api.787512.co.uk/';
        // return 'https://roomsapi.norfolkpassport.com/';
    };
    ApiService.prototype.v2Path = function () {
        return 'https://bpv2api.787512.co.uk/';
    };
    ApiService.prototype.getHttpOptions = function () {
        var token = this.store.selectForLocal('token');
        var httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
            })
        };
        return httpOptions;
    };
    ApiService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        return throwError('Something bad happened; please try again later.');
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.Store)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
