import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "../store";
import * as i3 from "@angular/common/http";
var VoucherService = /** @class */ (function () {
    function VoucherService(apiService, store, http) {
        this.apiService = apiService;
        this.store = store;
        this.http = http;
        this.env = environment;
    }
    VoucherService.prototype.getVoucher = function (id) {
        return this.http.get(this.env.apiV2Path + 'voucher/' + id)
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.prototype.getVouchers = function () {
        return this.http.get(this.env.apiV2Path + 'voucher')
            .pipe(map(function (data) {
            return data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.prototype.search = function (params) {
        var dataToSend = {
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'voucher/search', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.prototype.assignVoucherToDataSelection = function (params, voucher) {
        var dataToSend = {
            params: params,
            voucher: voucher
        };
        return this.http.post(this.env.apiV2Path + 'voucher/assign/data', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.prototype.create = function (voucher) {
        var dataToSend = {
            voucher: voucher
        };
        return this.http.post(this.env.apiV2Path + 'voucher', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.prototype.update = function (voucher) {
        var dataToSend = {
            voucher: voucher
        };
        return this.http.put(this.env.apiV2Path + 'voucher', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.prototype.delete = function (voucher) {
        return this.http.delete(this.env.apiV2Path + 'voucher/' + voucher.id)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.prototype.searchVoucherUsers = function (params) {
        var dataToSend = {
            params: params
        };
        return this.http.post(this.env.apiV2Path + 'voucher/users/', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.prototype.deleteUser = function (userId, voucherId) {
        return this.http.delete(this.env.apiV2Path + 'voucher/user/' + userId + '/' + voucherId)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.prototype.deleteAllUsers = function (voucherId) {
        return this.http.delete(this.env.apiV2Path + 'voucher/users/' + voucherId)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.prototype.getVoucherPromotions = function (params) {
        var dataToSend = {
            params: params
        };
        return this.http.post(this.env.apiV2Path + 'packages/voucher', dataToSend)
            .pipe(map(function (data) {
            return data.data;
        }, catchError(this.apiService.handleError)));
    };
    VoucherService.ngInjectableDef = i0.defineInjectable({ factory: function VoucherService_Factory() { return new VoucherService(i0.inject(i1.ApiService), i0.inject(i2.Store), i0.inject(i3.HttpClient)); }, token: VoucherService, providedIn: "root" });
    return VoucherService;
}());
export { VoucherService };
