import * as i0 from "@angular/core";
var ToolsService = /** @class */ (function () {
    function ToolsService() {
    }
    ToolsService.prototype.secureId = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    ToolsService.prototype.dateToEpochDay = function (date) {
        var theDate = new Date(date);
        var dateToConvert = new Date(theDate.getFullYear(), theDate.getMonth(), theDate.getDate(), 0, 0, 0);
        var daysSinceZero = ((dateToConvert.getTime() / 1000) / 86400);
        return Math.ceil(daysSinceZero);
    };
    ToolsService.prototype.dateFromEpochDay = function (daysSinceZero) {
        var result = new Date(1970, 0, 1);
        result.setDate(result.getDate() + daysSinceZero);
        return result;
    };
    ToolsService.prototype.buildDateString = function (date) {
        var yyyy = date.getFullYear();
        var mm = date.getMonth();
        var dd = date.getDate();
        var dateString = yyyy;
        if (mm < 9) {
            dateString = dateString + ("-0" + (mm + 1));
        }
        else {
            dateString = dateString + ("-" + (mm + 1));
        }
        if (dd < 10) {
            dateString = dateString + ("-0" + dd);
        }
        else {
            dateString = dateString + ("-" + dd);
        }
        return dateString;
    };
    ToolsService.ngInjectableDef = i0.defineInjectable({ factory: function ToolsService_Factory() { return new ToolsService(); }, token: ToolsService, providedIn: "root" });
    return ToolsService;
}());
export { ToolsService };
