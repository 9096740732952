import { BookingService } from './../../services/booking.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '../../store';
import { PassportService } from '../passport.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { GiftCardService } from 'src/app/services/gift-card.service';

@Component({
    selector: 'app-passport',
    templateUrl: './passport.component.html',
    styleUrls: ['./passport.component.css'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ]

})
export class PassportComponent implements OnInit {
    form: FormGroup;

    feedbackCommentData;

    isNew = true;
    dupeEmail = false;

    passport;
    passportId = '';
    dob = new Date();
    submitted = false;
    passportCompanies = [];
    tmpPassportPreferences = [];
    transactions = [];
    feedback = [];
    bookings = [];
    giftcards = [];

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],  // custom dropdown
            ['bold', 'italic', 'underline'],        // toggled buttons
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };


    titles = [
        { id: 1, name: 'Mr' },
        { id: 2, name: 'Mrs' },
        { id: 3, name: 'Miss' },
        { id: 4, name: 'Ms' },
        { id: 5, name: 'Dr' },
        { id: 6, name: 'Other' },
    ];
    genders = [
        { id: 'M', name: 'Male' },
        { id: 'F', name: 'Female' },
        { id: 'BN', name: 'Non-Binary' },
        { id: 'O', name: 'Other' },
    ];
    discountLevels = [
        { id: 53, name: '10%' },
        { id: 54, name: '20%' },
        { id: 55, name: '30%' },
        { id: 56, name: '40%' },
        { id: 57, name: '50%' },
    ];

    bookingParams = {
        userId: '',
        searchTerm: '',
        name: '',
        hotelID: '',
        upgraded: '',
        hasPromo: '',
        hasExtras: '',
        county: '',
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'booking.created DESC',
        sorts: [
            { ID: 'booking.created DESC', name: 'Recent First' },
            { ID: 'booking.created ASC', name: 'Recent Last' },
            { ID: 'hotel.name ASC', name: 'Hotel Name A-Z' },
            { ID: 'hotel.name DESC', name: 'Hotel Name Z-A' },
            { ID: 'county.name DESC', name: 'County A-Z' },
            { ID: 'county.name ASC', name: 'County Z-A' },
        ],
    };

    transactionParams = {
        eposNowId: 0,
        searchTerm: '',
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'en_transaction.createdAt DESC',
        sorts: [
            { ID: 'en_transaction.createdAt DESC', name: 'Recent First' },
            { ID: 'en_transaction.createdAt ASC', name: 'Recent Last' },
            { ID: 'en_brand.name ASC', name: 'Brand Name A-Z' },
            { ID: 'en_brand.name DESC', name: 'Brand Name Z-A' },
        ],
    };

    feedbackParams = {
        cardNumber: 0,
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'feedback_invite.sent DESC',
        sorts: [
            { ID: 'feedback_invite.sent DESC', name: 'Recent First' },
            { ID: 'feedback_invite.sent ASC', name: 'Recent Last' },
        ],
    };

    transactionsToView = [];

    get preferencesFormArray() {
        return this.form.controls.preferences as FormArray;
    }
    constructor(
        private store: Store,
        private passportService: PassportService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private bookingService: BookingService,
        private userService: UserService,
        private giftCardService: GiftCardService,
    ) {
        this.form = this.fb.group({
            title: ['', [Validators.required]],
            firstname: ['', [Validators.required, Validators.minLength(1)]],
            surname: ['', [Validators.required, Validators.minLength(1)]],
            company: ['', []],
            referredBy: ['', []],
            dob: ['', []],
            dobDay: ['', []],
            dobMonth: ['', []],
            dobYear: ['', []],
            gender: ['', []],
            add1: ['', []],
            add2: ['', []],
            town: ['', []],
            county: ['', []],
            postcode: ['', []],
            username: ['', [Validators.required]],
            telephone: ['', []],
            mobile: ['', []],
            staffGroup: [0, []],
            discountLevel: [0, []],
            isSupplier: [false, []],
            preferences: new FormArray([]),
            notes: ['', []]
        });

    }


    ngOnInit() {
        this.store.set('area', 'passport');
        this.passportId = this.route.snapshot.params.id;
        if (this.passportId === 'new') {
            this.isNew = true;
            this.passport = {
                title: { id: 0 },
                firstname: '',
                surname: '',
                company: '',
                referredBy: '',
                add1: '',
                add2: '',
                town: '',
                county: '',
                postcode: '',
                gender: '',
                dobDay: '',
                dobMonth: '',
                dobYear: '',
                mobile: '',
                telephone: '',
                username: '',
                staffGroup: 0,
                discountLevel: 0,
                isSupplier: false,
                notes: ''
            }
            this.getCompanies();
        } else {
            this.isNew = false;
            this.get();
        }
    }

    getCompanies() {
        this.passportService.getCompanies().subscribe(data => {
            this.passportCompanies = data.data;
            this.passportCompanies.forEach(company => {
                let v = false;
                if (this.tmpPassportPreferences.indexOf(company.id) >= 0) {
                    v = true;
                } else {
                    v = false;
                }
                this.preferencesFormArray.push(new FormControl(v));
            });
        })
    }

    get() {
        this.passportService.get(this.passportId).subscribe(data => {
            console.log(data.data)
            this.passport = data.data;
            this.form.patchValue({
                title: data.data.title.id,
                firstname: data.data.firstname,
                surname: data.data.surname,
                company: data.data.company,
                referredBy: data.data.referredBy,
                add1: data.data.add1,
                add2: data.data.add2,
                town: data.data.town,
                county: data.data.county,
                postcode: data.data.postcode,
                gender: data.data.gender,
                mobile: data.data.mobile,
                telephone: data.data.telephone,
                username: data.data.username,
                staffGroup: data.data.staffGroup,
                discountLevel: data.data.discountLevel,
                isSupplier: data.data.isSupplier,
                notes: data.data.notes
            })
            if (data.data.dob) {
                this.form.patchValue({
                    dobDay: +data.data.dob.dobDay,
                    dobMonth: +data.data.dob.dobMonth,
                    dobYear: +data.data.dob.dobYear,
                    dob: new Date(data.data.dobYear, (+data.data.dobMonth - 1), data.data.dobDay),
                })
                console.log(`patched date as ${data.data.dobYear}${data.data.dobMonth - 1} ${data.data.dobDay}`)
            }
            this.tmpPassportPreferences = data.data.preferences;
            this.getCompanies();
            this.giftCardsByUser();
            this.getBookings();
            this.getTransactions();

            this.feedbackParams.cardNumber = data.data.cardNumber;
            this.getFeedback();

        })
    }

    bstFix(d) {
        const fixedDate = new Date(d);
        if (fixedDate.getUTCHours() > 22) {
            fixedDate.setTime(fixedDate.getTime() + 6 * 60 * 60 * 1000);
        }
        return fixedDate;
    }

    onSubmit() {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        const selectedPassportCompanies = this.form.value.preferences
            .map((checked, i) => checked ? this.passportCompanies[i].id : null)
            .filter(v => v !== null);

        this.passport = {
            id: this.passportId,
            title: { id: this.form.value.title },
            username: this.form.value.username,
            firstname: this.form.value.firstname,
            surname: this.form.value.surname,
            company: this.form.value.company,
            referredBy: this.form.value.referredBy,
            add1: this.form.value.add1,
            add2: this.form.value.add2,
            town: this.form.value.town,
            county: this.form.value.county,
            postcode: this.form.value.postcode,
            dob: this.bstFix(new Date(this.form.value.dob)),
            gender: this.form.value.gender,
            mobile: this.form.value.mobile,
            telephone: this.form.value.telephone,
            isSupplier: this.form.value.isSupplier,
            staffGroup: this.form.value.staffGroup,
            discountLevel: this.form.value.discountLevel,
            preferences: selectedPassportCompanies,
            notes: this.form.value.notes,
        }

        let authObs: Observable<any>;
        if (this.isNew) {
            this.passport.id = '';
            authObs = this.passportService.create(this.passport);
        } else {
            authObs = this.passportService.update(this.passport);
        }

        authObs.subscribe(
            resData => {
                this.router.navigate(['/passports']);
            }
            , errorMessage => {
                console.log(errorMessage);
                // this.form.controls.password.setErrors({ 'incorrect': true });
            }
        );
    }

    numberPad(v) {
        if (v > 10) {
            return v;
        }
        const paddedNumber = '0' + v;
        console.log(paddedNumber)
        return paddedNumber;
    }

    reprint() {
        this.passportService.reprint(this.passportId).subscribe(data => {

        })
    }

    dupeCheckEmail() {
        this.passportService.dupeCheckEmail(this.form.value.username, this.passportId).subscribe(data => {
            console.log(data);
            this.dupeEmail = data.data;
            if (data.data) {
                this.form.controls.username.setErrors({ 'exists': true });
            }
        })
    }

    dupeCheckCardNumber() {

    }

    giftCardsByUser() {
        this.giftCardService.giftCardsByUser(this.passportId).subscribe(data => {
            console.log(data)
            this.giftcards = data.data;
        })

    }

    getBookings() {
        this.bookingParams.userId = this.passportId;
        this.bookingService.byCustomer(this.passportId).subscribe(data => {
            this.bookings = data;
        })
    }

    getTransactions() {
        this.transactionParams.eposNowId = this.passport.eposNowId;
        this.userService.loadTransactions(this.transactionParams).subscribe(data => {
            console.log(data.data);
            this.transactions = data.data.data;


            this.transactionParams.pages = Math.ceil(
                data.data.totalRecords / this.transactionParams.limit
            );
            this.transactionParams.totalRecords = data.data.totalRecords;
            this.transactionParams.pageArray = [];
            for (let i = 0; i < this.transactionParams.pages; i++) {
                this.transactionParams.pageArray.push(i);
            }

        })
    }
    getTransactionItems(id) {
        this.userService.loadTransactionItems(id).subscribe(data => {
            console.log(data.data);
            this.transactionsToView = data.data;
            console.log(this.transactionsToView);
        })

    }

    toggleViewingTransactions(t) {
        if (!t.viewingTransactions) {
            for (let i = 0; i < this.transactions.length; i++) {
                this.transactions[i].viewingTransactions = false;
            }

            this.getTransactionItems(t.transactionId);
            t.viewingTransactions = true;
        } else {
            t.viewingTransactions = false;
            this.transactionsToView = [];
        }
    }



    getFeedback() {
        this.userService.searchFeedback(this.feedbackParams).subscribe(data => {
            console.log(data.data);
            this.feedback = data.data.data;

            this.feedbackParams.pages = Math.ceil(
                data.data.totalRecords / this.feedbackParams.limit
            );
            this.feedbackParams.totalRecords = data.data.totalRecords;
            this.feedbackParams.pageArray = [];
            for (let i = 0; i < this.feedbackParams.pages; i++) {
                this.feedbackParams.pageArray.push(i);
            }

        })
    }
    toggleViewingFeedback(t) {
        if (!t.viewingFeedback) {
            for (let i = 0; i < this.feedback.length; i++) {
                this.feedback[i].viewingFeedback = false;
            }

            t.viewingFeedback = true;
        } else {
            t.viewingFeedback = false;
        }
    }


    sendWalletLink(passportId) {
        this.passportService.sendWalletLink(passportId).subscribe(data => {
            console.log(data)
            this.passport.linkSent = true
        })
    }

    sendPurchaseLink(confirmationId) {
        this.passportService.sendPurchaseLink(confirmationId).subscribe(data => {
            console.log(data)
            this.passport.purchaseLinkSent = true
        })
    }

    viewBooking(booking) {
        this.router.navigate(['/', 'bookings', booking.id])
    }

    addFeedbackComment(invite) {
        this.feedbackCommentData = {
            invite
        }
        console.log(this.feedbackCommentData);
    }
    feedbackCommentDone(e) {
        if (e.refresh) {
            const user = this.store.selectForLocal('user');

            for (let f = 0; f < this.feedback.length; f++) {
                console.log(this.feedback[f])
                if (this.feedback[f].id === this.feedbackCommentData.invite.id) {
                    this.feedback[f].staffResponse = e.staffResponse;
                    this.feedback[f].respondedAt = new Date();
                    this.feedback[f].username = `${user.firstname} ${user.surname}`
                }
            }
        }
        this.feedbackCommentData = undefined;
    }
}
