/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./site-down.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./site-down.component";
import * as i4 from "../services/settings.service";
var styles_SiteDownComponent = [i0.styles];
var RenderType_SiteDownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SiteDownComponent, data: {} });
export { RenderType_SiteDownComponent as RenderType_SiteDownComponent };
function View_SiteDownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Pull site down "]))], null, null); }
function View_SiteDownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Put site online "]))], null, null); }
function View_SiteDownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-center mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please confirm this action"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Confirm "]))], null, null); }
export function View_SiteDownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "text-center"], ["style", "margin-top: 60px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please proceed with caution - changing this will change the live running of the Booking Portal"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SiteDownComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SiteDownComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SiteDownComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.siteDown; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.siteDown; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.confirming; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_SiteDownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-site-down", [], null, null, null, View_SiteDownComponent_0, RenderType_SiteDownComponent)), i1.ɵdid(1, 114688, null, 0, i3.SiteDownComponent, [i4.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SiteDownComponentNgFactory = i1.ɵccf("app-site-down", i3.SiteDownComponent, View_SiteDownComponent_Host_0, {}, {}, []);
export { SiteDownComponentNgFactory as SiteDownComponentNgFactory };
