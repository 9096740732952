/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hotel-features-edit.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./hotel-features-edit.component";
import * as i6 from "../../services/hotel.service";
var styles_HotelFeaturesEditComponent = [i0.styles];
var RenderType_HotelFeaturesEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HotelFeaturesEditComponent, data: {} });
export { RenderType_HotelFeaturesEditComponent as RenderType_HotelFeaturesEditComponent };
function View_HotelFeaturesEditComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleFeature(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["style", "color: #28a745"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["check_circle"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_HotelFeaturesEditComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleFeature(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["style", "color: #dddddd"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["check_circle_outline"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_HotelFeaturesEditComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HotelFeaturesEditComponent_4)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HotelFeaturesEditComponent_5)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.selected; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_v.context.$implicit.selected; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_HotelFeaturesEditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "table", [["class", "mat-elevation-z2 example-full-width data-listing"], ["style", "margin-bottom: 30px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [["style", "width: 33%"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Feature Group"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [["style", "width: 33%"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Feature Name"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [["style", "width: 33%"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Selected"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HotelFeaturesEditComponent_3)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.features; _ck(_v, 11, 0, currVal_0); }, null); }
function View_HotelFeaturesEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HotelFeaturesEditComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.featureGroups; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HotelFeaturesEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HotelFeaturesEditComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.featureGroups.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HotelFeaturesEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hotel-features-edit", [], null, null, null, View_HotelFeaturesEditComponent_0, RenderType_HotelFeaturesEditComponent)), i1.ɵdid(1, 114688, null, 0, i5.HotelFeaturesEditComponent, [i6.HotelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HotelFeaturesEditComponentNgFactory = i1.ɵccf("app-hotel-features-edit", i5.HotelFeaturesEditComponent, View_HotelFeaturesEditComponent_Host_0, { hotelID: "hotelID" }, {}, []);
export { HotelFeaturesEditComponentNgFactory as HotelFeaturesEditComponentNgFactory };
