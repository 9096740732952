import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ToolsService {

    constructor() { }

    secureId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    dateToEpochDay(date) {
        const theDate = new Date(date);
        const dateToConvert = new Date(theDate.getFullYear(), theDate.getMonth(), theDate.getDate(), 0, 0, 0);
        const daysSinceZero = ((dateToConvert.getTime() / 1000) / 86400);
        return Math.ceil(daysSinceZero);
    }

    dateFromEpochDay(daysSinceZero) {
        const result = new Date(1970, 0, 1);
        result.setDate(result.getDate() + daysSinceZero);
        return result;
    }

    buildDateString(date) {
        const yyyy = date.getFullYear();
        let mm = date.getMonth();
        let dd = date.getDate();

        let dateString: any = yyyy;
        if (mm < 9) { dateString = dateString + `-0${mm + 1}` } else { dateString = dateString + `-${mm + 1}` }
        if (dd < 10) { dateString = dateString + `-0${dd}` } else { dateString = dateString + `-${dd}` }

        return dateString;
    }
}
