/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-group-delete.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./product-group-delete.component";
import * as i10 from "../../services/product.service";
import * as i11 from "../../store";
import * as i12 from "../../services/overlay.service";
var styles_ProductGroupDeleteComponent = [i0.styles];
var RenderType_ProductGroupDeleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductGroupDeleteComponent, data: {} });
export { RenderType_ProductGroupDeleteComponent as RenderType_ProductGroupDeleteComponent };
export function View_ProductGroupDeleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "aside"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "mat-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please Confirm"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["Are you sure you want to delete ", "?"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "margin-top-20 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["color", "warn"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Confirm Deletion"]))], function (_ck, _v) { _ck(_v, 3, 0); var currVal_4 = "warn"; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.product_group.name; _ck(_v, 8, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 11).disabled || null); var currVal_3 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_2, currVal_3); }); }
export function View_ProductGroupDeleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-group-delete", [], null, null, null, View_ProductGroupDeleteComponent_0, RenderType_ProductGroupDeleteComponent)), i1.ɵdid(1, 114688, null, 0, i9.ProductGroupDeleteComponent, [i10.ProductService, i11.Store, i12.OverlayService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductGroupDeleteComponentNgFactory = i1.ɵccf("app-product-group-delete", i9.ProductGroupDeleteComponent, View_ProductGroupDeleteComponent_Host_0, {}, {}, []);
export { ProductGroupDeleteComponentNgFactory as ProductGroupDeleteComponentNgFactory };
