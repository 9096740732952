import { Component, OnInit } from '@angular/core';
import { BookingService } from '../../services/booking.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-booking-view',
    templateUrl: './booking-view.component.html',
    styleUrls: ['./booking-view.component.css']
})
export class BookingViewComponent implements OnInit {

    id = '';
    booking: any;
    spaBooking: any;

    constructor(
        private bookingService: BookingService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        this.get();
    }

    get() {
        this.bookingService.get(this.id).subscribe(data => {
            this.booking = data;
            console.log(this.booking.trybeOrderId)
            if (this.booking.trybeOrderId) {
                this.getSpaOrder(this.booking.trybeOrderId);
            }
            console.log(this.booking)
        });
    }

    getBooking(Id) {
        this.id = Id;
        this.get();
    }

    getSpaOrder(trybeOrderId) {
        this.bookingService.getSpaOrder(trybeOrderId).subscribe(data => {
            console.log(data.data.data);
            this.spaBooking = data.data.data;
        })
    }
}
