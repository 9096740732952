import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store,
        private growlerService: GrowlerService,
    ) { }

    groupsGet() {
        const dataToSend = {
            data: {}
        };
        return this.http.post(this.apiService.path() + '/product/groups', dataToSend).subscribe((data: any) => {
            this.store.set('productGroups', data.data);
        });
    }

    groupsGetByHotel(hotels) {
        const dataToSend = {
            data: { hotels }
        };
        return this.http.post(this.apiService.path() + '/product/groups', dataToSend).subscribe((data: any) => {
            this.store.set('productGroups', data.data);
        });
    }
    groupGet(id) {
        const dataToSend = {
            id: id
        };

        return this.http.post(this.apiService.path() + '/product/group', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));

    }
    productsGet(product_group) {
        const dataToSend = {
            product_group: product_group
        };
        return this.http.post(this.apiService.path() + '/products', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }
    productGet(product) {
        const dataToSend = {
            id: product.ID
        };
        return this.http.post(this.apiService.path() + '/product', dataToSend).subscribe((data: any) => {
            this.productsGet(product.product_group);
            return data.data;
        });
    }
    sortProducts(products) {
        const dataToSend = {
            products
        }
        return this.http.post(this.apiService.path() + 'products/sort', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                }))
    }

    groupAdd(product_group) {
        const dataToSend = {
            product_group: product_group
        };
        return this.http.post(this.apiService.path() + '/product/group/add', dataToSend).pipe(map((data: any) => {
            this.groupsGet();
            return data.data;
        }));
    }
    groupUpdate(product_group) {
        const dataToSend = {
            product_group: product_group
        };
        return this.http.post(this.apiService.path() + '/product/group/update', dataToSend).pipe(map((data: any) => {
            // this.groupsGet();
            return data.data;
        }));
    }
    groupDelete(product_group) {
        const dataToSend = {
            id: product_group.ID
        };
        return this.http.post(this.apiService.path() + '/product/group/delete', dataToSend).pipe(map((data: any) => {
            // this.groupsGet();
            return data.data;
        }));
    }
    productAdd(product) {
        const dataToSend = {
            product: product
        };
        return this.http.post(this.apiService.path() + '/product/add', dataToSend).pipe(map((data: any) => {
            this.productsGet(product.product_group.ID);
            return data.data;
        }));
    }
    productUpdate(product) {
        const dataToSend = {
            product: product
        };
        return this.http.post(this.apiService.path() + '/product/update', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }
    productDelete(product) {
        const dataToSend = {
            id: product.ID
        };
        return this.http.post(this.apiService.path() + '/product/delete', dataToSend).pipe(map((data: any) => {
            this.productsGet(product.product_group.ID);
            return data.data;
        }));
    }

    getIndexedProducts(hotelID) {
        const dataToSend = {
            hotelID: hotelID
        };
        return this.http.post(this.apiService.path() + '/hotel/products/indexed', dataToSend).pipe(map((data: any) => {
            console.log(data)
            return data;
        }));
    }


    toggleProduct(roomId, product) {
        const dataToSend = {
            productId: product.id,
            roomId: roomId,
            selected: product.selected
        };
        return this.http.post(this.apiService.path() + '/room/product/index', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }


}
