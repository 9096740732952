import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { Store } from '../store';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./authentication.service";
import * as i4 from "../store";
var ChartService = /** @class */ (function () {
    function ChartService(apiService, http, authService, store) {
        this.apiService = apiService;
        this.http = http;
        this.authService = authService;
        this.store = store;
        this.chartColours = [
            'rgba(254,134,114, 0.5)',
            'rgba(230,125,108, 0.5)',
            'rgba(254,184,172, 0.5)',
            'rgba(254,155,138, 0.5)',
            'rgba(254,163,148, 0.5)',
            'rgba(225,96,75, 0.5)'
        ];
    }
    ChartService.prototype.initialLoadStore = function () {
        var _this = this;
        var dataToSend = {
            user: this.store.selectForLocal('user'),
        };
        return this.http.post(this.apiService.path() + 'charts/overview', dataToSend).subscribe(function (data) {
            _this.store.set('chartData', data.data);
        });
    };
    ChartService.ngInjectableDef = i0.defineInjectable({ factory: function ChartService_Factory() { return new ChartService(i0.inject(i1.ApiService), i0.inject(i2.HttpClient), i0.inject(i3.AuthenticationService), i0.inject(i4.Store)); }, token: ChartService, providedIn: "root" });
    return ChartService;
}());
export { ChartService };
